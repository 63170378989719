import React, { ReactElement } from 'react';
import cx from 'classnames';
import closeIcon from 'assets/widgetImages/close.svg';
import fullscreenIcon from 'assets/widgetImages/fullscreen.svg';

interface IChatHeaderProps {
  title: string
  subtitle: string
  toggleChat: () => void
  toggleFullscreen: () => void
  className?: string | undefined
  brandIcon?: ReactElement | undefined
  isHideFullscreen?: boolean | undefined
}

const ChatHeader: React.FC<IChatHeaderProps> = ({
  title,
  subtitle,
  className,
  brandIcon,
  toggleChat,
  isHideFullscreen,
  toggleFullscreen,
}) => (
  <div data-testid="cw-chat-header" className={cx('cw-chat-header', className)}>
    <div className="cw-chat-header__content">
      {brandIcon && (
        <div className="cw-brand-icon-wrapper">
          <div className="cw-brand-icon">
            {brandIcon}
          </div>
          <span className="cw-chat-status" />
        </div>
      )}
      <div className="cw-header-text">
        <p className="cw-header-title">{title}</p>
        <p className="cw-header-subtitle">{subtitle}</p>
      </div>
    </div>
    <div className="cw-header-buttons">
      {
        !isHideFullscreen && (
          <button data-testid="cw-fullscreen-button" type="button" className="cw-header-button" onClick={toggleFullscreen}>
            <img src={fullscreenIcon} alt="fullscreen" />
          </button>
        )
      }
      <button data-testid="cw-close-button" type="button" className="cw-header-button" onClick={toggleChat}>
        <img src={closeIcon} alt="close" />
      </button>
    </div>
  </div>
);

ChatHeader.defaultProps = {
  className: '',
  brandIcon: undefined,
  isHideFullscreen: false,
};

export default ChatHeader;
