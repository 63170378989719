import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import AddOrderButtonBlock from '@EngagementsForm/AddOrderButtonBlock';
import { useEngagementsFormPermission } from '@EngagementsForm/useEngagementsFormPermission';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import EngagementFormGeneralEditMode from '@EngagementsForm/EngagementsFormGeneral/EngagementFormGeneralEditMode';
import EngagementFormGeneralViewMode from '@EngagementsForm/EngagementsFormGeneral/EngagementFormGeneralViewMode';

const EngagementsFormGeneral = () => {
  const { wirelineEngagementId } = useParams();
  const {
    isEditModeGeneral, setIsEditModeGeneral,
  } = useWirelineEngagementsCreateContext();

  const {
    isAvailableCreateOrder,
  } = useEngagementsFormPermission();

  const isCreateMode = !wirelineEngagementId;

  const isVisibleAddOrderButton = !isCreateMode && isAvailableCreateOrder;

  const isVisibleEditMode = isCreateMode || isEditModeGeneral;

  return (
    <Grid
      className="engagements-forms__section"
      container
      direction="row"
      columnSpacing={5}
    >
      {
        !isVisibleEditMode && <EngagementFormGeneralViewMode setIsEditMode={setIsEditModeGeneral} />
      }
      {
        isVisibleEditMode && <EngagementFormGeneralEditMode />
      }
      {isVisibleAddOrderButton && <AddOrderButtonBlock />}
    </Grid>
  );
};

export default EngagementsFormGeneral;
