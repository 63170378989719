import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ButtonMenu from 'shared/ButtonMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTableContext } from 'shared/Table/TableContext';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { checkIfOrdersIsAttTSD } from '@EngagementsSowData/utilities';
import SowDataActionsRequest from '@EngagementsSowData/OrdersActionsMenu/SowDataActionsRequest';
// eslint-disable-next-line no-unused-vars
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import { checkIfOrdersRequestTypeIsNonCotractSupport } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';

import './SowDataActionsButton.scss';

interface IOrdersActionsMenuProps {
  selectedOrders: ISowDataListItem[],
  setIsActionsModalOpen: (isModalOpen: boolean) => void
  setOrderAction: (orderAction: OrderActions) => void
}

const OrdersActionsMenu: React.FC<IOrdersActionsMenuProps> = ({
  selectedOrders, setIsActionsModalOpen, setOrderAction,
}) => {
  const { rowSelection } = useTableContext();

  const hasRowNonAttTSD = checkIfOrdersIsAttTSD(selectedOrders);
  const hasRowRequestTypeIsNonContractOrder = checkIfOrdersRequestTypeIsNonCotractSupport(
    selectedOrders,
  );

  const isActionsBtnDisabled = hasRowNonAttTSD
    || hasRowRequestTypeIsNonContractOrder
    || isEmpty(rowSelection);

  return (
    <ButtonMenu
      buttonVariant="text"
      tooltipTitle="Actions"
      isDisabled={isActionsBtnDisabled}
      buttonContent={<MoreHorizIcon />}
      menuClassName="sow-menu-actions"
    >
      <SowDataActionsRequest
        selectedOrders={selectedOrders}
        setOrderAction={setOrderAction}
        setIsActionsModalOpen={setIsActionsModalOpen}
      />
    </ButtonMenu>
  );
};

export default OrdersActionsMenu;
