import React, {
  useState, useRef, ReactElement, useEffect,
} from 'react';
import cx from 'classnames';
import { IMessage } from 'shared/ChatWidget';
import ChatWindow from 'shared/ChatWidget/ChatWindow';

import './ChatWidget.scss';

interface IChatWidgetProps {
  title: string
  subtitle: string
  messages: IMessage[]
  classNameHeader?: string
  classNameMessages?: string
  isHideFullscreen?: boolean
  isLoadingResponce?: boolean
  classNameInputBlock?: string
  classNameChatWrapper?: string
  classNameMessagesCount?: string
  onSend: (message: string) => void
  botIcon?: ReactElement | undefined
  brandIcon?: ReactElement | undefined
  renderChatLauncher: (isOpen: boolean, onToggleChat: () => void) => ReactElement
}

const ChatWidget: React.FC<IChatWidgetProps> = ({
  title,
  onSend,
  botIcon,
  subtitle,
  brandIcon,
  messages = [],
  classNameHeader,
  isHideFullscreen,
  isLoadingResponce,
  classNameMessages,
  renderChatLauncher,
  classNameInputBlock,
  classNameChatWrapper,
  classNameMessagesCount,
}) => {
  const prevMessageCount = useRef<number>(messages?.length);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [newMessagesCount, setNewMessagesCount] = useState<number>(0);

  const isVisibleNewMessageCount = !isOpen && newMessagesCount > 0;

  const toggleChat = () => {
    setIsOpen((prev) => !prev);

    if (isOpen) {
      setNewMessagesCount(0);
      prevMessageCount.current = messages.length;
    }
  };

  const onSendHandler = () => {
    setInput('');
    onSend?.(input);
  };

  const calculateNewBotMessages = () => {
    if (isOpen) return;

    const messagesCount = messages.length - prevMessageCount.current;
    setNewMessagesCount(messagesCount);
  };

  useEffect(() => {
    calculateNewBotMessages();
  }, [messages]);

  return (
    <div className={cx('chat-widget', { open: isOpen })}>
      <div className="cw-chat-launcher-container">
        {renderChatLauncher(isOpen, toggleChat)}
        {
          isVisibleNewMessageCount && (
            <div className={cx('cw-new-messages-count', classNameMessagesCount)}>
              {newMessagesCount}
            </div>
          )
        }
      </div>
      {isOpen && (
        <ChatWindow
          isOpen
          title={title}
          value={input}
          botIcon={botIcon}
          messages={messages}
          onChange={setInput}
          subtitle={subtitle}
          brandIcon={brandIcon}
          onSend={onSendHandler}
          toggleChat={toggleChat}
          classNameHeader={classNameHeader}
          isHideFullscreen={isHideFullscreen}
          isLoadingResponce={isLoadingResponce}
          classNameMessages={classNameMessages}
          classNameInputBlock={classNameInputBlock}
          classNameChatWrapper={classNameChatWrapper}
        />
      )}
    </div>
  );
};

ChatWidget.defaultProps = {
  botIcon: undefined,
  classNameHeader: '',
  brandIcon: undefined,
  classNameMessages: '',
  isHideFullscreen: false,
  classNameInputBlock: '',
  isLoadingResponce: false,
  classNameChatWrapper: '',
  classNameMessagesCount: '',
};

export default ChatWidget;
