import React, { useState, ReactElement } from 'react';
import cx from 'classnames';

import { IMessage } from 'shared/ChatWidget';
import Messages from 'shared/ChatWidget/ChatWindow/Messages';
import ChatInput from 'shared/ChatWidget/ChatWindow/ChatInput';
import ChatHeader from 'shared/ChatWidget/ChatWindow/ChatHeader';

interface IChatWindowProps {
  title: string
  value: string
  isOpen: boolean
  subtitle: string
  onSend: () => void
  messages: IMessage[]
  toggleChat: () => void
  onChange: (value: string) => void
  botIcon?: ReactElement | undefined
  brandIcon?: ReactElement | undefined
  classNameHeader?: string | undefined
  isHideFullscreen?: boolean | undefined
  classNameMessages?: string | undefined
  isLoadingResponce?: boolean | undefined
  classNameInputBlock?: string | undefined
  classNameChatWrapper?: string | undefined
}

const ChatWindow: React.FC<IChatWindowProps> = ({
  title,
  value,
  isOpen,
  onSend,
  botIcon,
  messages,
  subtitle,
  onChange,
  brandIcon,
  toggleChat,
  classNameHeader,
  isHideFullscreen,
  isLoadingResponce,
  classNameMessages,
  classNameInputBlock,
  classNameChatWrapper,
}) => {
  const [isFullscreenMode, setFullscreenMode] = useState(false);

  const toggleFullscreen = () => setFullscreenMode(
    (currentFullscreenMode) => !currentFullscreenMode,
  );

  return (
    <div className={cx(
      'cw-chat-window',
      classNameChatWrapper,
      { 'cw-chat-window__fullscreen': isFullscreenMode })}
    >
      <ChatHeader
        title={title}
        subtitle={subtitle}
        brandIcon={brandIcon}
        toggleChat={toggleChat}
        className={classNameHeader}
        isHideFullscreen={isHideFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
      <Messages
        isOpen={isOpen}
        messages={messages}
        botIcon={botIcon}
        classNameMessages={classNameMessages}
        isLoadingResponce={isLoadingResponce}
      />
      <ChatInput
        value={value}
        onSend={onSend}
        onChange={onChange}
        className={classNameInputBlock}
        isFullscreenMode={isFullscreenMode}
        isLoadingResponce={isLoadingResponce}
      />
    </div>
  );
};

ChatWindow.defaultProps = {
  botIcon: undefined,
  classNameHeader: '',
  brandIcon: undefined,
  classNameMessages: '',
  isHideFullscreen: false,
  classNameInputBlock: '',
  classNameChatWrapper: '',
  isLoadingResponce: false,
};

export default ChatWindow;
