import React from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import OrderDataIcon from '@mui/icons-material/DescriptionOutlined';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { useActiveNumbersPermissions } from '@EngagementsActiveNumbers/useActiveNumbersPermissions';
import { checkIfOrderNonAttTSD, isActiveNumbersItemCanceled } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';

interface IWirelineEngagementsActiveNumbersRowActionsProps {
  row: IActiveNumbersItem;
}

const WirelineEngagementsActiveNumbersRowActions: React.FC<
  IWirelineEngagementsActiveNumbersRowActionsProps
> = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,

    setActiveRowEdit,
    setActiveRowView,
    setActiveRowComment,
    setActiveRowOrderData,
    setActiveRowCancelOrder,
    setActiveRowEscalationSupport,
  } = useTableActiveRowContext();

  const {
    isAvailableCancelOrder,
    isAvailableEditOrder,
    isAvailableGetOrderData,
    isAvailableGetComments,
    isAvailableEscalationSupport,
  } = useActiveNumbersPermissions();

  const {
    isCancelOrderMode, isEscalationSupportMode, isCommentMode, isDataMode,
  } = getTableRowModeBoolean(activeRowMode);

  const rowId = get(row, 'id');
  const isCanceled = isActiveNumbersItemCanceled(row);
  const isOrderDataButtonSelected = isDataMode && rowId === activeRowId;
  const isCommentButtonSelected = isCommentMode && rowId === activeRowId;
  const isCancelOrderButtonSelected = isCancelOrderMode && rowId === activeRowId;
  const isEscalationSupportBtnSelected = isEscalationSupportMode && rowId === activeRowId;
  const isOrderNonAttTSD = checkIfOrderNonAttTSD(row);

  const isEscalationSupportBtnDisabled = (
    (!!activeRowId && !isEscalationSupportBtnSelected) || isCanceled
  );

  const isCancelOrderButtonDisabled = (
    (!!activeRowId && !isCancelOrderButtonSelected)
    || isCanceled || isOrderNonAttTSD
  );

  const isCommentButtonDisabled = !!activeRowId && !isCommentButtonSelected;

  const isOrderDataButtonDisabled = !!activeRowId && !isOrderDataButtonSelected;

  const isAllActionButtonDisabled = isCancelOrderButtonDisabled
  && isCommentButtonDisabled
  && isOrderDataButtonDisabled
  && isEscalationSupportBtnDisabled;

  const isMoreButtonDisabled = isAllActionButtonDisabled && isOrderNonAttTSD;

  const moreButtonConfig = compact([
    (isAvailableEscalationSupport && {
      onClick: () => setActiveRowEscalationSupport(row),
      isDisabled: isEscalationSupportBtnDisabled,
      icon: <NextWeekOutlinedIcon />,
      text: 'Escalation Support',
    }),
    (isAvailableCancelOrder && {
      onClick: () => setActiveRowCancelOrder(row),
      isDisabled: isCancelOrderButtonDisabled,
      icon: <ClearIcon />,
      text: 'Cancel/Disco Request',
    }),
    (isAvailableGetOrderData && {
      onClick: () => setActiveRowOrderData(row),
      isDisabled: isOrderDataButtonDisabled,
      icon: <OrderDataIcon />,
      text: 'Order Data',
    }),
    (isAvailableGetComments && {
      onClick: () => setActiveRowComment(row),
      isDisabled: isCommentButtonDisabled,
      icon: <CommentIcon />,
      text: 'Order Comments',
    }),
  ]);

  return (
    <Actions<IActiveNumbersItem>
      row={row}
      activeRowId={activeRowId}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      setActiveRowView={setActiveRowView}
      moreButtonConfig={moreButtonConfig}
      moreButtonTooltipTitle={orderActionTitle}
      isActiveButtonDisabled={isOrderNonAttTSD}
      isMoreButtonDisabled={isMoreButtonDisabled}
      isActionButtonVisible={isAvailableEditOrder}
    />
  );
};

export default WirelineEngagementsActiveNumbersRowActions;
