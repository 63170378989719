import React from 'react';
import cx from 'classnames';

import SendButton from 'shared/ChatWidget/ChatWindow/SendButton';
import ChatTextarea from 'shared/ChatWidget/ChatWindow/ChatTextarea';
import { useSpeechRecognition } from 'shared/ChatWidget/useSpeechRecognition';
import SpeechRecognitionButton from 'shared/ChatWidget/ChatWindow/SpeechRecognitionButton';
import { textareaSpeechPlaceholder, textareaTypePlaceholder } from 'shared/ChatWidget/constants';

interface IChatInputProps {
  value: string
  onSend: () => void
  isFullscreenMode: boolean
  className?: string | undefined
  onChange: (value: string) => void
  isLoadingResponce?: boolean | undefined
}

const ChatInput: React.FC<IChatInputProps> = ({
  value,
  onSend,
  onChange,
  className,
  isFullscreenMode,
  isLoadingResponce,
}) => {
  const {
    isListening,
    isSpeechRecognitionSupported,
    stopListening,
    startListening,
  } = useSpeechRecognition({ onChangeTranscript: onChange });

  const isDisabledSend = !value.trim() || isListening || !!isLoadingResponce;
  const textareaPlaceholder = isListening ? textareaSpeechPlaceholder : textareaTypePlaceholder;

  return (
    <div data-testid="cw-chat-input" className={cx('cw-chat-input', className)}>
      <ChatTextarea
        value={value}
        onSend={onSend}
        onChange={onChange}
        isDisabled={isListening}
        isDisabledSend={isDisabledSend}
        placeholder={textareaPlaceholder}
        isFullscreenMode={isFullscreenMode}
      />
      <div className="cw-chat-input__actions">
        {
          isSpeechRecognitionSupported && (
            <SpeechRecognitionButton
              onChange={onChange}
              isListening={isListening}
              stopListening={stopListening}
              startListening={startListening}
              isSpeechRecognitionSupported={isSpeechRecognitionSupported}
            />
          )
        }
        <SendButton onSend={onSend} isDisabled={isDisabledSend} />
      </div>
    </div>
  );
};

ChatInput.defaultProps = {
  className: '',
  isLoadingResponce: false,
};

export default ChatInput;
