// eslint-disable-next-line no-unused-vars
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { sellerRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

export const projectManagerEngagementAcceptValidation = (row: IActiveNumbersItem) => {
  const { orderNumber, sellerRequestType, pmEngagementAcceptedDate } = row || {};

  const isContractOrderSupport = sellerRequestType === sellerRequestTypeValues.contractSupport;
  const isBillingSupport = sellerRequestType === sellerRequestTypeValues.billingSuport;
  const isProjectManagerSupport = sellerRequestType
  === sellerRequestTypeValues.projectManagerSupport;

  const isContractEngagementAllowed = isContractOrderSupport
    && !!orderNumber
    && !pmEngagementAcceptedDate;
  const isBillingAndProjectManagerEngagementAllowed = (isBillingSupport || isProjectManagerSupport)
    && !pmEngagementAcceptedDate;

  return isContractEngagementAllowed || isBillingAndProjectManagerEngagementAllowed;
};

export const billingSupportEngagementAcceptValidation = (row: IActiveNumbersItem) => {
  const { billingEngagementAcceptedDate, sellerRequestType, initiatedEngagementDate } = row || {};

  const isContractOrderSupport = sellerRequestType === sellerRequestTypeValues.contractSupport;
  const isBillingSupport = sellerRequestType === sellerRequestTypeValues.billingSuport;
  const isProjectManagerSupport = sellerRequestType
  === sellerRequestTypeValues.projectManagerSupport;

  const isContractEngagementAllowed = isContractOrderSupport
    && !!initiatedEngagementDate
    && !billingEngagementAcceptedDate;

  const isBillingEngagementAllowed = isBillingSupport && !billingEngagementAcceptedDate;

  return !isProjectManagerSupport && (isContractEngagementAllowed || isBillingEngagementAllowed);
};

export const commonRoleEngagementAcceptValidation = (row: IActiveNumbersItem) => {
  const {
    orderNumber, sellerRequestType, pmEngagementAcceptedDate, billingEngagementAcceptedDate,
  } = row || {};

  const isContractOrderSupport = sellerRequestType === sellerRequestTypeValues.contractSupport;
  const isBillingSupport = sellerRequestType === sellerRequestTypeValues.billingSuport;
  const isProjectManagerSupport = sellerRequestType
    === sellerRequestTypeValues.projectManagerSupport;

  const isContractEngagementAllowed = isContractOrderSupport
    && !!orderNumber
    && (!pmEngagementAcceptedDate || !billingEngagementAcceptedDate);

  const isBillingAndProjectManagerEngagementAllowed = (isBillingSupport || isProjectManagerSupport)
    && (!pmEngagementAcceptedDate || !billingEngagementAcceptedDate);

  return isContractEngagementAllowed || isBillingAndProjectManagerEngagementAllowed;
};

export const engagementAcceptValidate = (
  {
    row,
    isAvailableEngagementAcceptPM,
    isAvailableEngagementAcceptBilling,
  }: {
    row: IActiveNumbersItem,
    isAvailableEngagementAcceptPM: boolean,
    isAvailableEngagementAcceptBilling: boolean,
  },
) => {
  if (isAvailableEngagementAcceptPM && isAvailableEngagementAcceptBilling) {
    return commonRoleEngagementAcceptValidation(row);
  }

  if (isAvailableEngagementAcceptPM) {
    return projectManagerEngagementAcceptValidation(row);
  }

  if (isAvailableEngagementAcceptBilling) {
    return billingSupportEngagementAcceptValidation(row);
  }

  return false;
};
