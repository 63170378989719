import React from 'react';
import { useFormikContext } from 'formik';
import CustomSidebar from 'shared/CustomSidebar';
import { TableRowMode } from 'shared/Table/Table.models';
import { getDrawerStyle } from 'pages/WirelineEngagements/shared/utilites';
import EditRowSidebarFields from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields';
import './EditRowSidebar.scss';

interface IEditRowSidebarProps {
  title: string
  schemaName: string
  isViewMode?: boolean
  isLoadingItem: boolean
  allowedFields: string[]
  cleanEditRow: () => void
  topActions?: JSX.Element | undefined,
  secondarySidebarMode: TableRowMode | null
  editRowId: string | number | undefined | null
  disabledFields?: { [key: string]: boolean } | undefined
  setSecondarySidebarMode: (mode: TableRowMode | null) => void
}

const EditRowSidebar: React.FC<IEditRowSidebarProps> = ({
  editRowId, cleanEditRow, title, isLoadingItem, schemaName, isViewMode,
  disabledFields, setSecondarySidebarMode, secondarySidebarMode, allowedFields,
  topActions,
}) => {
  const {
    dirty, isSubmitting, submitForm, setErrors, resetForm,
  } = useFormikContext();

  const withLeaveConfirmation = !isViewMode && dirty && !isSubmitting;

  const closeEditRowSidebar = () => {
    cleanEditRow();
    setErrors({});
    resetForm();
    setSecondarySidebarMode(null);
  };

  const drawerStyle = getDrawerStyle(!!secondarySidebarMode);

  return (
    <CustomSidebar
      title={title}
      isOpen={!!editRowId}
      topAction={topActions}
      onClickSave={submitForm}
      drawerStyle={drawerStyle}
      onCloseSideBar={closeEditRowSidebar}
      isConfirmDialog={withLeaveConfirmation}
      areButtonsVisible={!isViewMode as boolean}
    >
      <EditRowSidebarFields
        editRowId={editRowId}
        schemaName={schemaName}
        isLoadingItem={isLoadingItem}
        allowedFields={allowedFields}
        disabledFields={disabledFields}
        isDisabled={isViewMode as boolean}
      />
    </CustomSidebar>

  );
};

EditRowSidebar.defaultProps = {
  isViewMode: false,
  disabledFields: {},
  topActions: undefined,
};

export default EditRowSidebar;
