import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { Tooltip } from '@mui/material';

import { speechTooltip, speechTooltipTimeout } from 'shared/ChatWidget/constants';
import { ReactComponent as RecordingIcon } from 'assets/widgetImages/recording.svg';
import { ReactComponent as RecordingStop } from 'assets/widgetImages/recordingStop.svg';

interface ISpeechRecognitionButtonProps {
  isListening: boolean
  isSpeechRecognitionSupported: boolean
  stopListening: () => void
  startListening: () => void
  onChange: (value: string) => void
}

const SpeechRecognitionButton: React.FC<ISpeechRecognitionButtonProps> = ({
  isListening,
  isSpeechRecognitionSupported,
  onChange,
  stopListening,
  startListening,
}) => {
  const timeoutId = useRef<unknown>(null);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const onClearTimeout = () => {
    clearTimeout(timeoutId.current as number);
    setIsOpenTooltip(false);
  };

  const showTooltip = () => {
    if (timeoutId.current) {
      onClearTimeout();
    }

    setIsOpenTooltip(true);

    timeoutId.current = setTimeout(() => {
      setIsOpenTooltip(false);
    }, speechTooltipTimeout);
  };

  const onStartSpeachRecognition = () => {
    startListening();
    showTooltip();
    onChange('');
  };

  const onStopSpeachRecognition = () => {
    stopListening();
    onClearTimeout();
  };

  const onToggleSpeechRecognition = () => (isListening
    ? onStopSpeachRecognition()
    : onStartSpeachRecognition()
  );

  useEffect(() => () => {
    onClearTimeout();
  }, []);

  if (!isSpeechRecognitionSupported) return null;

  return (
    <Tooltip
      arrow
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={isOpenTooltip}
      placement="top-start"
      title={speechTooltip}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth: 'none',
          },
        },
      }}
    >
      <button
        type="button"
        data-testid="cw-speech-button"
        onClick={onToggleSpeechRecognition}
        className={cx('cw-chat-input__actions-speech', {
          active: isListening,
        })}
      >
        {isListening ? <RecordingStop /> : <RecordingIcon />}
      </button>
    </Tooltip>
  );
};

export default SpeechRecognitionButton;
