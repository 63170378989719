import React from 'react';
import Table from 'shared/Table';
import { useAppSelector } from 'core/store';
import { TRow } from 'shared/Table/Table.models';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { useActiveNumbersPermissions } from '@EngagementsActiveNumbers/useActiveNumbersPermissions';
import ActiveNumbersEngagementAccepted from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted';
import ActiveNumbersTableModals from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals';
import ExportActiveNumbersTable from '@EngagementsActiveNumbers/ActiveNumbersTable/ExportActiveNumbersTable';
import { colDefOptionActionsSingleIcon, colDefOptionActionsTwoIcons, colDefOptionSelect } from 'shared/Table/constants';
import { defaultColumnPinning, muiTableContainerProps } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import WirelineEngagementsActiveNumbersRowActions from '@EngagementsActiveNumbers/WirelineEngagementsActiveNumbersRowActions';

const ActiveNumbersTable = () => {
  const isActiveNumbersLoading = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.isLoading);

  const activeNumbersData = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.results);

  const activeNumbersCount = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.count);

  const {
    isAvailableMoreActions,
    isAvailableTwoRowAction,
    isAvailableTableActions,
    isAvailableSomeRowAction,
    isAvailableExportTableData,
  } = useActiveNumbersPermissions();

  const actionsButton = isAvailableTableActions
    ? <ActiveNumbersEngagementAccepted />
    : undefined;

  const renderRowActions = isAvailableMoreActions
    ? ({ row: { original } }) => (
      <WirelineEngagementsActiveNumbersRowActions
        row={original as unknown as IActiveNumbersItem}
      />
    ) : undefined;

  const bottomToolbarCustomActions = isAvailableExportTableData
    ? () => (
      <ExportActiveNumbersTable
        activeNumbersItem={activeNumbersData[0]}
      />
    ) : undefined;

  return (
    <>
      <Table
        showGlobalFilter
        enableStickyHeader
        enableSelectAll={false}
        showColumnFilters={false}
        className="wireline-table"
        rowCount={activeNumbersCount}
        isLoading={isActiveNumbersLoading}
        initialColumnPinning={defaultColumnPinning}
        rows={activeNumbersData as unknown as TRow[]}
        muiTableContainerProps={muiTableContainerProps}
        defaultVisibility={{ id: false, orderType: false }}
        actionsButton={actionsButton}
        renderBottomToolbarCustomActions={bottomToolbarCustomActions}
        renderRowActions={renderRowActions}
        displayColumnDefOptions={{
          ...(isAvailableTableActions && colDefOptionSelect),
          ...(isAvailableSomeRowAction && colDefOptionActionsSingleIcon),
          ...(isAvailableTwoRowAction && colDefOptionActionsTwoIcons),
        }}
      />
      <ActiveNumbersTableModals />
    </>
  );
};

export default ActiveNumbersTable;
