import React, { useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { EngagementType } from 'pages/InsideSales/model';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { IServerValidationError } from 'core/services/HTTPService.models';
import HeaderActions from 'pages/WirelineEngagements/shared/HeaderActions';
import EditRowSidebar from 'pages/WirelineEngagements/shared/EditRowSidebar';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { getParsedRowData } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { ACTIONS_WIRELINE_ENGAGEMENT_ACTIVE_NUMBERS_PATHS } from 'core/models/actionsPermissions';
import { additianalOrderFields } from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields/constants';
import { clearGetActiveNumbersItemData, getSalesActiveNumbersItemAction } from 'core/actions/Sales/GetSalesActiveNumbersItem';
import { clearEditActiveNumbersItemData, patchSalesActiveNumbersItemAction } from 'core/actions/Sales/EditSalesActiveNumbersItem';
import { includedFields, alwaysActiveFieldsForPM } from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EditRowSidebarForm/constants';
import {
  addAdditionalFieldsToOrder,
  omitAdditionalFieldsFromOrder,
} from 'pages/WirelineEngagements/shared/EditRowSidebar/utilities';
import {
  editActiveNumbersTitle,
  viewActiveNumbersTitle,
  getActiveNumbersItemNotifierMessage,
  dynamicActiveNumbersProductSchemaName,
  patchActiveNumbersItemNotifierMessage,
} from '@EngagementsActiveNumbers/constants';

const {
  ORDER_DATA_GET,
  COMMENTS_GENERAL_GET,
  COMMENTS_BILLING_GET,
  ENGAGEMENT_ACCEPT_PM_CREATE,
} = ACTIONS_WIRELINE_ENGAGEMENT_ACTIVE_NUMBERS_PATHS;

const actionsPaths = {
  isAvailableGetOrderData: ORDER_DATA_GET,
  isAvailableGetGeneralComments: COMMENTS_GENERAL_GET,
  isAvailableGetBillingComments: COMMENTS_BILLING_GET,
  isAvailableEngagementAcceptPM: ENGAGEMENT_ACCEPT_PM_CREATE,
};

const EditRowSidebarForm = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();
  const { getTableData } = useTableContext();

  const {
    isAvailableGetOrderData,
    isAvailableGetGeneralComments,
    isAvailableGetBillingComments,
    isAvailableEngagementAcceptPM,
  } = useActionsPermissions(actionsPaths);

  const {
    activeRowId, cleanActiveRow, activeRowMode,
    secondarySidebarMode, setSecondarySidebarMode,
  } = useTableActiveRowContext();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const salesActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.salesActiveNumbersItem.result,
  );
  const isLoadingActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.salesActiveNumbersItem.isLoading,
  );
  const isLoadingEditActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.editSalesActiveNumbersItem.isLoading,
  );

  const isLoading = isLoadingActiveNumbersItem || isLoadingEditActiveNumbersItem;

  const { editableActiveNumbersData, salesActiveNumbersItemKeys } = useMemo(() => {
    const editableData = getParsedRowData({
      includedFields,
      isEditable: true,
      rowData: salesActiveNumbersItem,
    });
    const itemKeys = Object.keys(editableData);

    return { editableActiveNumbersData: editableData, salesActiveNumbersItemKeys: itemKeys };
  }, [salesActiveNumbersItem]);

  const disabledFields = useMemo(() => {
    if (!isAvailableEngagementAcceptPM) { return; }

    const isCanceled = editableActiveNumbersData.pmEngagementType === EngagementType.CANCELED;
    return salesActiveNumbersItemKeys
      .reduce(
        (acc, str) => ({ ...acc, [str]: isCanceled && !alwaysActiveFieldsForPM.includes(str) }),
        {});
  },
  [editableActiveNumbersData, salesActiveNumbersItemKeys]);

  const initialFormData = useMemo(() => addAdditionalFieldsToOrder({
    fields: additianalOrderFields,
    order: salesActiveNumbersItem,
    parsedOrder: editableActiveNumbersData,
  }),
  [salesActiveNumbersItem, editableActiveNumbersData]);

  const title = isEditMode ? editActiveNumbersTitle : viewActiveNumbersTitle;
  const editRowId = (isEditMode || isViewMode) ? activeRowId : undefined;

  const isAvailableGetComments = isAvailableGetGeneralComments || isAvailableGetBillingComments;

  const topActions = (
    <HeaderActions
      secondarySidebarMode={secondarySidebarMode}
      isVisibleCommentsButton={isAvailableGetComments}
      setSecondarySidebarMode={setSecondarySidebarMode}
      isVisibleOrderDataButton={isAvailableGetOrderData}
    />
  );

  const onSubmit = async (params, { setErrors, resetForm }) => {
    if (!activeRowId || !isEditMode) return;

    const formatedParams = omitAdditionalFieldsFromOrder({
      order: params,
      mandatoryFields: includedFields,
      initialOrder: editableActiveNumbersData,
      additionalFields: additianalOrderFields,
    });

    try {
      await dispatch(patchSalesActiveNumbersItemAction({
        id: activeRowId,
        params: formatedParams,
      })).unwrap();

      cleanActiveRow();
      setSecondarySidebarMode(null);
      showSuccessNotifier(patchActiveNumbersItemNotifierMessage.success);
      getTableData();
      resetForm();
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);
      showErrorNotifier(patchActiveNumbersItemNotifierMessage.error, definedError);
    }
  };

  const getActiveNumbersItemData = async () => {
    try {
      dispatch(getSalesActiveNumbersItemAction(activeRowId as number))
        .unwrap();
    } catch (error) {
      showErrorNotifier(getActiveNumbersItemNotifierMessage.error, error);
    }
  };

  useEffect(() => {
    if (!activeRowId || !(isViewMode || isEditMode)) return;
    getActiveNumbersItemData();

    return () => {
      dispatch(clearGetActiveNumbersItemData());
      dispatch(clearEditActiveNumbersItemData());
    };
  }, [activeRowId]);

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialFormData}
    >
      <Form>
        <EditRowSidebar
          title={title}
          editRowId={editRowId}
          topActions={topActions}
          isViewMode={isViewMode}
          isLoadingItem={isLoading}
          cleanEditRow={cleanActiveRow}
          disabledFields={disabledFields}
          allowedFields={salesActiveNumbersItemKeys}
          secondarySidebarMode={secondarySidebarMode}
          setSecondarySidebarMode={setSecondarySidebarMode}
          schemaName={dynamicActiveNumbersProductSchemaName}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
