import React, { useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import FormikAutocompleteField from 'shared/FormikField/FormikAutocompleteField';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
// eslint-disable-next-line no-unused-vars
import { ICustomerListItem } from 'pages/Customers/Customers.models';
import { debounceTime } from 'core/constants';
import { getCustomers } from 'core/api/customer.api';
import { useFormikContext } from 'formik';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useParams } from 'react-router-dom';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import { IEngagementsForm } from '@EngagementsForm/EngagementsForm.model';

const getOptionsFromCustomers = (customers: ICustomerListItem[]): IOption[] => (
  customers.map(({ companyName }) => ({
    value: companyName,
    label: companyName,
  }))
);

const EngagementsFormGeneralCompanyNameSearch = () => {
  const { wirelineEngagementId } = useParams();
  const isCreateMode = !wirelineEngagementId;

  const { setFieldValue, values } = useFormikContext<IEngagementsForm>();
  const { showErrorNotifier } = useNotifiers();
  const { setIsCreateCustomerModalOpen } = useWirelineEngagementsCreateContext();

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(values.companyName);
  const [customers, setCustomers] = useState<ICustomerListItem[]>([]);

  const addCustomer = () => {
    setIsCreateCustomerModalOpen(true);
  };

  const AddCustomerButton = (
    <Button
      variant="outlined"
      onClick={addCustomer}
      disabled={!isCreateMode}
      sx={{ height: '37px' }}
    >
      <AddIcon />
      Add
    </Button>
  );

  const loadOptions = useCallback(
    async (newInputValue: string) => {
      if (!newInputValue) return;

      try {
        setIsLoading(true);
        const { data: { results } } = await getCustomers({ search: newInputValue });
        setCustomers(results);
      } catch (e) {
        showErrorNotifier('Error while loading customers', e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setCustomers],
  );

  const loadOptionsDebounce = useCallback(
    debounce(loadOptions, debounceTime),
    [loadOptions, loadOptions],
  );

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    setCustomers([]);

    loadOptionsDebounce(newInputValue);
  };

  const onChange = (option: IOption | null) => {
    if (!option) {
      setFieldValue('selectedCustomer', null);
      return;
    }

    const selectedCustomer = customers.find(({ companyName }) => companyName === option.value);

    setFieldValue('selectedCustomer', selectedCustomer);
  };

  const defaultValue = useMemo(() => {
    const { companyName } = values;

    if (!companyName) {
      return undefined;
    }

    return { value: companyName, label: companyName } as IOption;
  }, [values.companyName]);

  const options = useMemo(() => getOptionsFromCustomers(customers), [customers]);

  return (
    <FormikAutocompleteField
      fieldName="companyName"
      label="Customer Name"
      defaultValue={defaultValue}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      options={options}
      isLoading={isLoading}
      Action={AddCustomerButton}
      disabled={!isCreateMode}
      required
      fullWidth
      key={values.companyName}
    />
  );
};

export default EngagementsFormGeneralCompanyNameSearch;
