import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'core/routing/paths';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EngagementsFormScopeDescriptionActions
  from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/EngagementsFormScopeDescription/EngagementsFormScopeDescriptionActions';
import './WirelineEngagementsCreateBackButton.scss';

const WirelineEngagementsCreateBackButton = () => {
  const { isInitializing } = useWirelineEngagementsCreateContext();
  const { wirelineEngagementId } = useParams();

  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(paths.wirelineEngagementsList);
  };

  if (isInitializing) {
    return null;
  }

  const title = wirelineEngagementId ? 'Edit engagements' : 'Create engagements';

  return (
    <Grid
      container
      className="back-to-edit-engagement__button_wrap"
      alignItems="center"
      pt={2}
      pl={2}
    >
      <div className="back-to-edit-engagement__container">
        <div className="back-to-edit-engagement__button">
          <IconButton
            onClick={onGoBack}
            color="primary"
          >
            <ArrowBackIosIcon
              color="action"
            />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            className="back-to-edit-engagement__title"
          >
            { title }
          </Typography>
        </div>
        {
          wirelineEngagementId && (
          <div>
            <EngagementsFormScopeDescriptionActions />
          </div>
          )
        }
      </div>
    </Grid>
  );
};

export default WirelineEngagementsCreateBackButton;
