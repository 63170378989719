import { ITableCell } from 'shared/Table/Table.models';
import { US_STATES } from 'core/models/states.models';
import { IAttachment } from 'shared/widgets/Attachments/Attachments.model';
import { IComment } from 'shared/widgets/Comments/Comments.models';

export interface ICustomerLocation {
  id: number
  address: string
  city: string
  state: US_STATES
  zipCode: string
  // eslint-disable-next-line no-restricted-globals
  name: string
  alterAddress: string
  phoneNumber: string
  customerId: number
  isEditable: boolean
  creatorFirstName: string
  creatorLastName: string
  createdDatetime: string
}

interface ICustomerBillingInfo {
  address: string,
  suite: string,
  city: string,
  state: string,
  zipCode: number
}

interface ICustomerProfile {
  firstName?: string
  lastName?: string
  title?: string
  email?: string
  phoneNumber?: string
}

export enum EFFECTIVE_PERIOD {
  THREE_YEARS = '3Y',
  TWO_YEARS = '2Y',
  ONE_YEAR = '1Y',
  SIX_MONTHS = '6M',
  THREE_MONTHS = '3M',
}

export interface ICustomerLoaProfile extends ICustomerProfile {
  effectivePeriod: EFFECTIVE_PERIOD
  sendOn?: string | null
  signOn?: string | null
}

export interface ICustomer {
  id: number
  seller: number
  sellerId: number
  sellerOptionalFirst?: number
  sellerOptionalSecond?: number
  companyName: string
  subAccountId?: string
  loaPdf?: string
  billingInfo: ICustomerBillingInfo
  loaInfoProfile: ICustomerLoaProfile
  primaryItProfile: ICustomerProfile
  contractSignerProfile: ICustomerProfile
  alternativeContactProfile: ICustomerProfile
}

export interface ICustomerListItem {
  id: number
  // eslint-disable-next-line no-restricted-globals
  status: number
  companyName: string
  billingInfoAddress: string
  primaryContact: string
  primaryContactEmail: string
  seller: string
  netsparkReps: string[],
  contractSpecialists: string[],
  projectManagers: string[],
  subAccountId?: string,
}

export type ITableCellCustomer = ITableCell<ICustomerListItem>;

export interface ICustomerAttachment extends IAttachment {
  customerId: number
}

export interface ICustomerComment extends IComment {
  customerId: number
}
