import React, {
  ReactElement, useEffect, useRef,
} from 'react';
import cx from 'classnames';
import { IMessage } from 'shared/ChatWidget';
import Message from 'shared/ChatWidget/ChatWindow/Message';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import MessageLoader from 'shared/ChatWidget/ChatWindow/MessageLoader';

interface IMessagesProps {
  isOpen: boolean
  messages: IMessage[]
  botIcon?: ReactElement | undefined
  classNameMessages?: string | undefined
  isLoadingResponce?: boolean | undefined
}

const Messages: React.FC<IMessagesProps> = ({
  isOpen,
  botIcon,
  messages,
  isLoadingResponce,
  classNameMessages,
}) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = (behavior: ScrollBehavior) => {
    if (!isOpen || !messagesEndRef.current) { return; }

    messagesEndRef.current.scrollIntoView({ behavior });
  };

  useEffect(() => {
    scrollToBottom('auto');
  }, [isOpen]);

  useUpdateEffect(() => {
    scrollToBottom('smooth');
  }, [messages, isLoadingResponce]);

  useEffect(() => {
    if (!messagesRef.current) return;

    const observer = new ResizeObserver(() => {
      scrollToBottom('auto');
    });
    observer.observe(messagesRef.current);

    return () => {
      if (!observer) return;

      observer.disconnect();
    };
  }, [messagesRef]);

  return (
    <div
      ref={messagesRef}
      data-testid="cw-chat-messages"
      className={cx('cw-chat-messages', classNameMessages)}
    >
      {messages.map((message) => (
        <Message
          key={message.id}
          botIcon={botIcon}
          message={message}
        />
      ))}
      {isLoadingResponce && <MessageLoader botIcon={botIcon} />}
      <div ref={messagesEndRef} />
    </div>
  );
};

Messages.defaultProps = {
  botIcon: undefined,
  classNameMessages: '',
  isLoadingResponce: false,
};

export default Messages;
