import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { useAppSelector } from 'core/store';
import paths, { getUserEditPath } from 'core/routing/paths';
import CreateButton from 'shared/CreateButton';
import Table from 'shared/Table';
import { TableProvider } from 'shared/Table/TableContext';
import { TRow } from 'shared/Table/Table.models';
import { tableIds } from 'shared/Table/constants';
import getUserManagementLisAction from 'pages/UserManagement/UserManagementList/GetUserManagementList/actions';
import { getColumns, createUserButtonText } from 'pages/UserManagement/UserManagementList/constants';

const CustomersList = () => {
  const listData = useAppSelector((state) => state.users.list);
  const isLoading = useAppSelector((state) => state.users.list.isLoading);
  const navigate = useNavigate();
  const brandName = useAppSelector((state) => state.appVariables.data.brand);

  const createUserAction = (
    <CreateButton
      className="user-create-btn"
      onClick={() => navigate(paths.userManagementCreate)}
      text={createUserButtonText}
    />
  );

  const handleRowClick = useCallback((row) => {
    navigate(getUserEditPath(row.original.id));
  }, [navigate]);

  return (
    <div className="user-list">
      <TableProvider
        id={tableIds.userManagement}
        columns={getColumns(brandName) as ColumnDef<TRow>[]}
        getDataAction={getUserManagementLisAction}
      >
        <Table
          isLoading={isLoading}
          rowCount={listData.count}
          toolBarAction={createUserAction}
          showColumnFilters
          showGlobalFilter
          rows={listData.results}
          handleRowClick={handleRowClick}
        />
      </TableProvider>
    </div>
  );
};

export default CustomersList;
