import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import paths from 'core/routing/paths';
import { GroupMenuItems } from 'shared/ButtonMenu';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useUserPermissions } from '@EngagementsSowData/useSowDataPermissions';
import { SowDataActions } from '@EngagementsSowData/OrdersActionsMenu/constsnts';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import {
  quoteRequestDisabledConditions,
  orderRequestDisabledConditions,
  contractRequestDisabledMainConditions,
  contractRequestAndOrderRequestDisabledConditions,
} from '@EngagementsSowData/utilities';

interface ISowDataActionsRequestProps {
  selectedOrders: ISowDataListItem[],
  setOrderAction: (orderAction: OrderActions) => void,
  setIsActionsModalOpen: (isModalOpen: boolean) => void
}

const SowDataActionsRequest: React.FC<
  ISowDataActionsRequestProps
> = ({
  selectedOrders, setOrderAction, setIsActionsModalOpen,
}) => {
  const navigate = useNavigate();

  const {
    isAvailableQuoteRequest,
    isAvailableOrderRequest,
    isAvailableContractRequest,
  } = useUserPermissions();

  const {
    orderIds,
    isDisableQuotesRequest,
    isDisabledRequestOrders,
    isDisableContractRequest,
  } = useMemo(() => {
    const isContractAndOrderRequestDisabled = selectedOrders.some(
      contractRequestAndOrderRequestDisabledConditions,
    );

    const everyOrdersHasRequestQuoteDatetime = selectedOrders.every(
      (order) => order.requestQuoteDatetime,
    );
    const isQuoteRequestDisabledByAdditionalConditions = selectedOrders.some(
      quoteRequestDisabledConditions,
    );

    const isContractRequestDisabledByMainConditions = selectedOrders.some(
      contractRequestDisabledMainConditions,
    );

    const isOrderRequestDisabledByAdditionalConditions = selectedOrders.some(
      orderRequestDisabledConditions,
    );

    const selectedOrderIds = selectedOrders.map(({ id }) => id).join(',');

    return {
      orderIds: selectedOrderIds,
      isDisableQuotesRequest: everyOrdersHasRequestQuoteDatetime
      || isQuoteRequestDisabledByAdditionalConditions,
      isDisableContractRequest: isContractRequestDisabledByMainConditions
      || isContractAndOrderRequestDisabled,
      isDisabledRequestOrders: isOrderRequestDisabledByAdditionalConditions
      || isContractAndOrderRequestDisabled,
    };
  }, [selectedOrders]);

  const onRequestQuotes = async () => {
    setOrderAction(OrderActions.REQUEST_QUOTES);
    setIsActionsModalOpen(true);
  };

  const onRequestContracts = () => {
    setOrderAction(OrderActions.REQUEST_CONTRACTS);
    navigate({
      pathname: paths.wirelineEngagementsSowDataContractForm.split('/').pop() as string,
      search: `?${createSearchParams({ orderIds })}`,
    });
  };

  const onRequestOrders = () => {
    setOrderAction(OrderActions.REQUEST_ORDERS);
    navigate({
      pathname: paths.wirelineEngagementsRequestOrder.split('/').pop() as string,
      search: `?${createSearchParams({ orderIds })}`,
    });
  };

  const menuItems = compact([
    (isAvailableQuoteRequest && {
      label: SowDataActions.quotes,
      onClick: onRequestQuotes,
      isDisabled: isDisableQuotesRequest,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__request-icon" />,
    }),
    (isAvailableContractRequest && {
      label: SowDataActions.contracts,
      onClick: onRequestContracts,
      isDisabled: isDisableContractRequest,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__request-icon" />,
    }),
    (isAvailableOrderRequest && {
      label: SowDataActions.orders,
      onClick: onRequestOrders,
      isDisabled: isDisabledRequestOrders,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__request-icon" />,
    }),
  ]);

  return (
    <GroupMenuItems
      groupLabel="Request"
      listItems={menuItems}
    />
  );
};

export default SowDataActionsRequest;
