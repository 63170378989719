import { netsparkAxios } from 'core/services/HTTPService';
import { guideChatCreateMessage } from './apiUrl';
// eslint-disable-next-line no-unused-vars
import { IGuideChatCreateMessage, IGuideChatCreateMessageParams } from './GuideChat.models';

export const postGuideChatMessage = (params: IGuideChatCreateMessageParams) => (
  netsparkAxios.post<IGuideChatCreateMessage>(guideChatCreateMessage, params)
);

export default postGuideChatMessage;
