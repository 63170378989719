import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'shared/Loader';
import ContentShrink from 'shared/ContentShrink';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { getWidthToReduce } from 'pages/WirelineEngagements/shared/utilites';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import ActiveNumbersTable from '@EngagementsActiveNumbers/ActiveNumbersTable';
// eslint-disable-next-line no-unused-vars
import { IRequestParams } from '@EngagementsActiveNumbers/activeNumbers.model';
import { clearSalesActiveNumbersData } from 'core/actions/Sales/GetSalesActiveNumbers';
import getSalesActiveNumbersAction from 'core/actions/Sales/GetSalesActiveNumbers/actions';
import { getColumnsFromMetaAndShchema } from 'core/utilities/getColumnsFromMetaAndShchema';
import { useActiveNumbersPermissions } from '@EngagementsActiveNumbers/useActiveNumbersPermissions';
import BackToEditEngagementButton from 'pages/WirelineEngagements/shared/BackToEditEngagementButton';
import { getSalesActiveNumbersMetaAction, clearSalesActiveNumbersMetaAction } from 'core/actions/Sales/GetSalesActiveNumbersMeta';
import { checkIfOrderNonAttTSD, isActiveNumbersItemCanceled } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { engagementAcceptValidate } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsActiveNumbers/utilities';
import {
  getColumns,
  activeNumbersTitle,
  dynamicActiveNumbersProductSchemaName,
} from '@EngagementsActiveNumbers/constants';
import 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsTables.scss';
import './WirelineEngagementsActiveNumbers.scss';

const WirelineEngagementsActiveNumbersContainer = () => {
  const dispatch = useAppDispatch();
  const { customerId } = useParams();

  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const isOpenapiSchemasLoading = (useAppSelector((state) => state.openapiSchemas.isLoading));
  const metaColumns = useAppSelector((
    state,
  ) => state.wirelineEngagements.salesActiveNumbersMeta.data);
  const isLoadingMeta = useAppSelector((
    state,
  ) => state.wirelineEngagements.salesActiveNumbersMeta.isLoading);
  const brandName = useAppSelector((state) => state.appVariables.data.brand);

  const retrieveSchema = useMemo(() => parseOpenapiSchema(
    openApiSchemas,
    dynamicActiveNumbersProductSchemaName,
  ), [openApiSchemas],
  );

  const activeNumbersColumns = useMemo(
    () => getColumnsFromMetaAndShchema({
      columns: getColumns(brandName), metaColumns, retrieveSchema,
    }),
    [retrieveSchema, metaColumns]);

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext();
  const {
    isEditMode,
    isViewMode,
    isDataMode,
    isCommentMode,
  } = getTableRowModeBoolean(activeRowMode);

  const {
    isAvailableEditOrder,
    isAvailableTableActions,
    isAvailableEngagementAcceptPM,
    isAvailableEngagementAcceptBilling,
  } = useActiveNumbersPermissions();

  const enableRowSelection = (
    isAvailableTableActions && ((row) => {
      const isCanceled = isActiveNumbersItemCanceled(row.original);
      const isNonAtt = checkIfOrderNonAttTSD(row.original);
      const isAvailableEngagementAceept = engagementAcceptValidate({
        row: row.original,
        isAvailableEngagementAcceptPM,
        isAvailableEngagementAcceptBilling,
      });

      return !isCanceled && !isNonAtt && isAvailableEngagementAceept;
    })
  );

  const isTableShrank = isEditMode || isDataMode || isViewMode || isCommentMode;

  const getSalesActiveNumbersMeta = () => {
    dispatch(getSalesActiveNumbersMetaAction());
  };

  const clearReduxData = () => {
    dispatch(clearSalesActiveNumbersData());
    dispatch(clearSalesActiveNumbersMetaAction());
  };

  useEffect(() => {
    getSalesActiveNumbersMeta();
    return () => {
      clearReduxData();
    };
  }, []);

  if (!customerId) {
    return null;
  }

  const requestParams: IRequestParams = {
    customerId,
  };

  if (isLoadingMeta || isOpenapiSchemasLoading) {
    return <Loader />;
  }

  return (
    <div className="active-numbers">
      <BackToEditEngagementButton title={activeNumbersTitle} />
      <ContentShrink
        isShrinked={isTableShrank}
        widthToReduce={getWidthToReduce(!!secondarySidebarMode)}
      >
        <TableProvider<IRequestParams>
          id={tableIds.activeNumbers}
          requestParams={requestParams}
          columns={activeNumbersColumns}
          enableEditing={isAvailableEditOrder}
          enableRowSelection={enableRowSelection}
          getDataAction={getSalesActiveNumbersAction}
        >
          <ActiveNumbersTable />
        </TableProvider>
      </ContentShrink>
    </div>
  );
};

export default WirelineEngagementsActiveNumbersContainer;
