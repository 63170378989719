import React, { ReactElement } from 'react';

interface IMessageLoaderProps {
  botIcon?: ReactElement | undefined
}

const MessageLoader: React.FC<IMessageLoaderProps> = ({
  botIcon,
}) => (
  <div data-testid="cw-loader-message" className="cw-message cw-bot-message">
    {botIcon
      && (
        <div className="cw-bot-icon">
          {botIcon}
        </div>
      )}
    <div className="cw-message-text">
      <span className="cw-message-loader-dots" />
    </div>
  </div>
);

MessageLoader.defaultProps = {
  botIcon: undefined,
};

export default MessageLoader;
