import React from 'react';
import { useActiveNumbersPermissions } from '@EngagementsActiveNumbers/useActiveNumbersPermissions';
import DataRowSidebar from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/DataRowSidebar';
import CommentsSidebar from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/CommentsSidebar';
import CancelOrderModal from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/CancelOrderModal';
import EditRowSidebarForm from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EditRowSidebarForm';
import EscalationSupportModal from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EscalationSupportModal';

const ActiveNumbersTableModals = () => {
  const {
    isAvailableEditOrder,
    isAvailableGetComments,
    isAvailableCancelOrder,
    isAvailableGetOrderData,
    isAvailableEscalationSupport,
  } = useActiveNumbersPermissions();

  return (
    <>
      { isAvailableEditOrder && <EditRowSidebarForm /> }
      { isAvailableEscalationSupport && <EscalationSupportModal /> }
      { isAvailableCancelOrder && <CancelOrderModal /> }
      { isAvailableGetComments && <CommentsSidebar /> }
      { isAvailableGetOrderData && <DataRowSidebar /> }
    </>
  );
};

export default ActiveNumbersTableModals;
