import { Grid, Typography } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import IconButtonWithTooltip from 'shared/IconButtonWithTooltip';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import getFormInitialDataFromEngagement from '@EngagementsForm/utilities';
import { numericStatusOptions } from 'shared/constants';
import './EngagementsFormGeneral.scss';

const EngagementFormGeneralViewMode = ({ setIsEditMode }) => {
  const {
    newCompanyName,
    engagementsInitialValues,
  } = useWirelineEngagementsCreateContext();
  const initialValues = getFormInitialDataFromEngagement(
    engagementsInitialValues, newCompanyName, undefined,
  );
  const toggleToEditMode = () => {
    setIsEditMode(true);
  };
  const engagementType = numericStatusOptions
    .find((option) => option.value === initialValues.engagementType)?.label;
  const customerStatus = numericStatusOptions
    .find((option) => option.value === initialValues.selectedCustomer?.status)?.label;

  return (
    <Grid
      item
      xs={12}
      className="engagement-forms__view-mode"
    >
      <div className="engagement-forms__title-container">
        <Typography
          className="engagement-forms__section-title"
          variant="body1"
          component="p"
        >
          Customer Information
        </Typography>
        <IconButtonWithTooltip
          title="Edit"
          onClick={toggleToEditMode}
        >
          <EditIcon />
        </IconButtonWithTooltip>
      </div>
      <div className="engagement-forms__data-container">
        <div className="engagement-forms__data">
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              Engagement Type
            </div>
            <div className="engagement-forms__data-item-value">
              {
                engagementType
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              Customer Name
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.selectedCustomer?.companyName
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              SubAgent Name
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.subAgentName
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              Technical Overlay
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.technicalOverlay as string
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              BDR Support
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.bdsSupport
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              Direct Seller
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.directSeller
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              Customer Status
            </div>
            <div className="engagement-forms__data-item-value">
              {
                customerStatus
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              Sub-Account ID
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.selectedCustomer?.subAccountId
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              NetSpark Rep
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.selectedCustomer?.netsparkReps
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              Contract Specialist
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.selectedCustomer?.contractSpecialists
              }
            </div>
          </div>
          <div className="engagement-forms__data-item">
            <div className="engagement-forms__data-item-label">
              Project Manager
            </div>
            <div className="engagement-forms__data-item-value">
              {
                initialValues.selectedCustomer?.projectManagers
              }
            </div>
          </div>
        </div>
      </div>
    </Grid>

  );
};

export default EngagementFormGeneralViewMode;
