import React, { useRef, useEffect } from 'react';

interface IChatTextareaProps {
  value: string
  onSend: () => void
  placeholder: string
  isDisabled: boolean
  isDisabledSend: boolean
  isFullscreenMode: boolean
  onChange: (value: string) => void
}

const ChatTextarea: React.FC<IChatTextareaProps> = ({
  value,
  onSend,
  onChange,
  isDisabled,
  placeholder,
  isDisabledSend,
  isFullscreenMode,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isDisabled) return;

    onChange(e.target.value);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isDisabledSend) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      onSend();
    }
  };

  const updateTextAreaHeight = () => {
    if (!textareaRef.current) return;

    textareaRef.current.style.height = 'auto';

    const { scrollHeight } = textareaRef.current;
    textareaRef.current.style.height = `${scrollHeight}px`;
    textareaRef.current.scrollTop = scrollHeight;
  };

  useEffect(() => {
    updateTextAreaHeight();
  }, [value, isFullscreenMode]);

  return (
    <label htmlFor="cw-chat-textarea">
      <textarea
        data-testid="cw-chat-textarea"
        id="cw-chat-textarea"
        ref={textareaRef}
        value={value}
        rows={1}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
      />
    </label>
  );
};

export default ChatTextarea;
