import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_WIRELINE_ENGAGEMENT_PATHS, ACTIONS_WIRELINE_ENGAGEMENT_SOW_PATHS } from 'core/models/actionsPermissions';

const { ORDER_CREATE } = ACTIONS_WIRELINE_ENGAGEMENT_PATHS;
const {
  QUOTE_CREATE,
  ORDER_DATA_GET,
  CONTRACT_CREATE,
  ORDER_PARTIAL_EDIT,
  CANCEL_ORDER_CREATE,
  ORDER_REQUEST_CREATE,
  COMMENTS_GENERAL_GET,
  COMMENTS_BILLING_GET,
  DUPLICATE_ORDER_CREATE,
  ESCALATION_SUPPORT_CREATE,
  EXPORT_SOW_DATA_TABLE_GET,
} = ACTIONS_WIRELINE_ENGAGEMENT_SOW_PATHS;

const actionsPaths = {
  isAvailableCreateOrder: ORDER_CREATE,
  isAvailableQuoteRequest: QUOTE_CREATE,
  isAvailableGetOrderData: ORDER_DATA_GET,
  isAvailableEditOrder: ORDER_PARTIAL_EDIT,
  isAvailableCancelOrder: CANCEL_ORDER_CREATE,
  isAvailableContractRequest: CONTRACT_CREATE,
  isAvailableOrderRequest: ORDER_REQUEST_CREATE,
  isAvailableDuplicateOrder: DUPLICATE_ORDER_CREATE,
  isAvailableGetGeneralComments: COMMENTS_GENERAL_GET,
  isAvailableGetBillingComments: COMMENTS_BILLING_GET,
  isAvailableExportSOWDataTable: EXPORT_SOW_DATA_TABLE_GET,
  isAvailableEscalationSupportOrder: ESCALATION_SUPPORT_CREATE,
};

export const useUserPermissions = () => {
  const {
    isAvailableEditOrder,
    isAvailableCreateOrder,
    isAvailableQuoteRequest,
    isAvailableOrderRequest,
    isAvailableContractRequest,
    isAvailableGetGeneralComments,
    isAvailableGetBillingComments,
    isAvailableCancelOrder,
    isAvailableGetOrderData,
    isAvailableDuplicateOrder,
    isAvailableEscalationSupportOrder,
    isAvailableExportSOWDataTable,
  } = useActionsPermissions(actionsPaths);

  const isAvailableGetComments = isAvailableGetBillingComments || isAvailableGetGeneralComments;

  const isAvailableMoreAction = isAvailableCancelOrder
    || isAvailableGetOrderData
    || isAvailableGetComments
    || isAvailableDuplicateOrder
    || isAvailableEscalationSupportOrder;

  const isAvailableTableActions = isAvailableQuoteRequest
    || isAvailableOrderRequest
    || isAvailableContractRequest;

  const isAvailableSomeRowAction = isAvailableEditOrder || isAvailableMoreAction;
  const isAvailableTwoRowAction = isAvailableEditOrder && isAvailableMoreAction;

  return {
    isAvailableEditOrder,
    isAvailableMoreAction,
    isAvailableCreateOrder,
    isAvailableCancelOrder,
    isAvailableGetComments,
    isAvailableTableActions,
    isAvailableTwoRowAction,
    isAvailableGetOrderData,
    isAvailableQuoteRequest,
    isAvailableOrderRequest,
    isAvailableSomeRowAction,
    isAvailableDuplicateOrder,
    isAvailableContractRequest,
    isAvailableExportSOWDataTable,
    isAvailableEscalationSupportOrder,
  };
};
