import { combineReducers } from '@reduxjs/toolkit';

import getStatesReducer from 'layouts/BaseLayout/GetStates/reducer';
import { getUserProfileReducer } from 'layouts/BaseLayout/GetUserProfile';
import getAppVariablesReducer from 'core/state/GetAppVariables/reducer';
import NotifierReducer from 'shared/Notifier/reducer';
import customersReducer from 'pages/Customers/reducer';
import userManagementReducer from 'pages/UserManagement/reducer';
import wirelineEngagementsReducer from 'pages/WirelineEngagements/reducer';
import dashboardReducer from 'pages/Dashboard/reducer';
import insideSalesReducer from 'pages/InsideSales/reducer';
import { openapiSchemasReducer } from 'core/actions/Openapi/GetOpenapiSchema';
import { getProductsReducer } from 'core/actions/Sales/GetProducts';
import contractManagementReducer from 'pages/ContractManagment/reducer';

import { guideChatReducer } from 'layouts/Sidebar/ChatWidgetWrapper/GuideChat';

const rootReducer = combineReducers({
  appVariables: getAppVariablesReducer,
  states: getStatesReducer,
  userProfile: getUserProfileReducer,
  notifier: NotifierReducer,
  customers: customersReducer,
  users: userManagementReducer,
  wirelineEngagements: wirelineEngagementsReducer,
  dashboard: dashboardReducer,
  insideSales: insideSalesReducer,
  openapiSchemas: openapiSchemasReducer,
  products: getProductsReducer,
  contractManagement: contractManagementReducer,

  guideChat: guideChatReducer,
});

export default rootReducer;
