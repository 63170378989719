import React from 'react';
import { useFormikContext } from 'formik';
import {
  Grid, Typography, Button,
} from '@mui/material';
import { dateFormatWithTime } from 'core/constants';
import EditSaveControls from 'shared/EditSaveControls';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';
import formatDatetime from 'core/utilities/dateUtilities/formatDatetime';
import { IScopeDescription, IScopeDescriptionForm } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/ScopeDescription.model';
import 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/EngagementFormsWrapper.scss';

interface EngagementsFormScopeDescriptionParams {
  isEditMode: boolean
  isVisibleActionsButton: boolean
  scopeDescription: IScopeDescription
  toggleEditMode: (isEditMode: boolean) => void
}

const EngagementsFormScopeDescription: React.FC<EngagementsFormScopeDescriptionParams> = ({
  isEditMode,
  toggleEditMode,
  scopeDescription,
  isVisibleActionsButton,
}) => {
  const { submitForm, resetForm } = useFormikContext<IScopeDescriptionForm>();

  const formattedModifiedDatetime = formatDatetime(
    scopeDescription?.modifiedDatetime,
    dateFormatWithTime,
  );

  return (
    <Grid
      className="engagements-forms__section"
      container
      direction="row"
      columnSpacing={5}
    >
      <Grid
        className="engagements-forms__scope-container"
        item
        container
        xs={12}
        pb={3}
      >
        <Grid
          item
          container
          direction="row"
          xs={12}
        >
          <Grid
            item
            container
            direction="row"
            xs={12}
          >
            <Grid
              item
              container
              direction="row"
              xs={12}
              className="engagement-forms__title-container"
            >
              <Typography
                className="engagements-forms__section-title"
                variant="body1"
                component="p"
              >
                Scope Description
              </Typography>
              {isVisibleActionsButton && !isEditMode
                  && (
                  <EditSaveControls
                    onSave={submitForm}
                    isEditMode={isEditMode}
                    onCancelEdit={resetForm}
                    setIsEditMode={toggleEditMode}
                  />
                  )}
            </Grid>
            <Grid
              item
              xs={12}
              mb={1}
            >
              <Typography
                className="engagements-forms__section-subtitle"
                variant="body1"
                component="p"
              >
                {formattedModifiedDatetime && `Edited: ${formattedModifiedDatetime}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item mt={1} xs={12}>
            { isEditMode && (
            <>
              <FormikTextareaField
                fieldName="text"
                disabled={!isEditMode}
              />
              <div className="engagements-forms__scope-container_buttons">
                <Button
                  variant="contained"
                  onClick={submitForm}
                  sx={{ height: '37px', width: '103px' }}
                >
                  SAVE
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => toggleEditMode(false)}
                  sx={{ height: '37px', width: '103px' }}
                >
                  CANCEL
                </Button>
              </div>
            </>
            )}
            {
              !isEditMode && (
              <div>
                {scopeDescription?.text}
              </div>
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EngagementsFormScopeDescription;
