import React, { useState, useMemo } from 'react';
import cx from 'classnames';
import {
  Button, Menu, MenuItem, ListItemIcon, ListItemText,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { engagementAcceptedTitle } from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/constants';
import '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAcceptedMenuButton/EngagementAcceptedMenuButton.scss';

const { BILLING_SPECIALIST, PROJECT_MANAGER } = UserTeamRoleTeamDepartment;

interface IEngagementAcceptedMenuButton {
  isDisabled: boolean;
  onSelect: (userRole: string) => void;
  selectedActiveNumbers: IActiveNumbersItem[];
}

const EngagementAcceptedMenuButton: React.FC<IEngagementAcceptedMenuButton> = ({
  onSelect,
  isDisabled,
  selectedActiveNumbers,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const onSelectHandler = (userRole: string, isDisabledItem: boolean) => {
    if (isDisabledItem) return;

    onSelect(userRole);
    handleCloseMenu();
  };

  const menuItems = useMemo(() => {
    const isDisabledBSButton = selectedActiveNumbers
      .some((item) => item.billingEngagementAcceptedDate);
    const isDisabledPMButton = selectedActiveNumbers
      .some((item) => item.pmEngagementAcceptedDate);

    return [
      {
        label: 'Billing Specialist',
        isDisabled: isDisabledBSButton,
        icon: <CheckCircleOutlineIcon className="bs_icon" />,
        onClick: () => onSelectHandler(BILLING_SPECIALIST, isDisabledBSButton),
      },
      {
        label: 'Project Manager',
        isDisabled: isDisabledPMButton,
        icon: <CheckCircleOutlineIcon className="pm_icon" />,
        onClick: () => onSelectHandler(PROJECT_MANAGER, isDisabledPMButton),
      },
    ];
  }, [selectedActiveNumbers]);

  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={handleOpenMenu}
        id="active-numbers-actions-btn"
        data-testid="engagement-accepted-menu-button"
        startIcon={<CheckCircleOutlineIcon />}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        aria-controls={isOpen ? 'active-numbers-engagement-menu' : undefined}
      >
        {engagementAcceptedTitle}
      </Button>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        id="active-numbers-engagement-menu"
        MenuListProps={{
          'aria-labelledby': 'active-numbers-actions-btn',
        }}
      >
        {menuItems.map(({
          onClick, isDisabled: isItemDisabled, icon, label,
        }) => (
          <MenuItem
            key={label}
            onClick={onClick}
            disabled={isItemDisabled}
          >
            {icon && (
              <ListItemIcon
                className={cx({
                  'item-disabled': isItemDisabled,
                })}
              >
                {icon}
              </ListItemIcon>
            )}
            <ListItemText>
              {label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EngagementAcceptedMenuButton;
