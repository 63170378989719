import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@mui/material';
import { useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { useActiveNumbersPermissions } from '@EngagementsActiveNumbers/useActiveNumbersPermissions';
import { engagementAcceptedTitle } from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/constants';
import EngagementAcceptedModal from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAcceptedModal';
import EngagementAcceptedMenuButton from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAcceptedMenuButton';

const { BILLING_SPECIALIST, PROJECT_MANAGER } = UserTeamRoleTeamDepartment;

const ActiveNumbersEngagementAccepted = () => {
  const { setRowSelection } = useTableContext();

  const [actionUserRole, setActionUserRole] = useState('');
  const [isEngagementAcceptedModalOpen, setIsEngagementAcceptedModalOpen] = useState(false);

  const activeNumbersData = useAppSelector(
    (state) => state.wirelineEngagements.activeNumbers.results,
  );

  const selectedActiveNumbers = useTableSelectedRows<IActiveNumbersItem>(
    { rows: activeNumbersData },
  );

  const {
    isAvailableEngagementAcceptPM,
    isAvailableAllRolesEngagementAccepted,
    isAvailableEngagementAcceptBilling,
  } = useActiveNumbersPermissions();

  const isDisabledEngagementAccepted = isEmpty(selectedActiveNumbers);

  const onOpenEngagementAcceptedModal = (roleUser: string) => {
    setActionUserRole(roleUser);
    setIsEngagementAcceptedModalOpen(true);
  };

  const onCloseEngagementAcceptedModal = () => {
    setIsEngagementAcceptedModalOpen(false);
    setRowSelection({});
    setActionUserRole('');
  };

  const onOpenEngagementAcceptedModalSingleRole = () => {
    const availableRoles = {
      [PROJECT_MANAGER]: isAvailableEngagementAcceptPM,
      [BILLING_SPECIALIST]: isAvailableEngagementAcceptBilling,
    };

    const availableRole = Object.entries(availableRoles)
      .reduce<null | string>((value, current) => (current[1] ? current[0] : value), null);

    if (!availableRole) return;

    onOpenEngagementAcceptedModal(availableRole);
  };

  return (
    <div className="active-number__engagement-acceptance">
      {!isAvailableAllRolesEngagementAccepted && (
        <Button
          startIcon={<CheckCircleOutlineIcon />}
          disabled={isDisabledEngagementAccepted}
          onClick={onOpenEngagementAcceptedModalSingleRole}
        >
          {engagementAcceptedTitle}
        </Button>
      )}
      {isAvailableAllRolesEngagementAccepted && (
        <EngagementAcceptedMenuButton
          onSelect={onOpenEngagementAcceptedModal}
          isDisabled={isDisabledEngagementAccepted}
          selectedActiveNumbers={selectedActiveNumbers}
        />
      )}
      <EngagementAcceptedModal
        actionUserRole={actionUserRole}
        isModalOpen={isEngagementAcceptedModalOpen}
        onCloseModal={onCloseEngagementAcceptedModal}
        selectedActiveNumbers={selectedActiveNumbers}
      />
    </div>
  );
};

export default ActiveNumbersEngagementAccepted;
