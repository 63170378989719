export const CONTINUONS = true;
export const INTERIM_RESULTS = true;
export const LANG = 'en-US';

export const textareaTypePlaceholder = 'Write a message';
export const textareaSpeechPlaceholder = 'Speak now';

export const speechTooltip = "Recording ongoing. Please stop when you're done.";

export const speechTooltipTimeout = 3500;
