import React, { useEffect } from 'react';
import { useField } from 'formik';
import { Grid } from '@mui/material';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import { ISchemaSellerRequestType } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { useAppSelector } from 'core/store';

interface IAddOrderRequestTypeField {
  itemCount: number;
  schema: ISchemaSellerRequestType;
}

const AddOrderRequestTypeField: React.FC<IAddOrderRequestTypeField> = ({
  schema,
  itemCount,
}) => {
  const {
    type, fieldName, options, nested,
  } = schema;
  const fullFieldName = `products[${itemCount}].params[${fieldName}]`;
  const [currentNestedField,, currentNestedHelpers] = useField(fullFieldName);
  const [paramsField,, helpersParamsField] = useField(`products[${itemCount}].params`);
  const brandName = useAppSelector((state) => state.appVariables.data.brand);

  const onChange = () => {
    const nestedFielEmptyData = nested
      ? Object.fromEntries(
        Object.values(nested)
          .map((item) => ([(item as {fieldName: string}).fieldName, ''])),
      )
      : {};
    if (Object.keys(nestedFielEmptyData)?.length) {
      helpersParamsField.setValue({ ...paramsField.value, ...nestedFielEmptyData });
    }
  };

  const brandOptions = options?.[brandName] || options?.default || [];

  const getElement = () => {
    switch (type) {
      case 'text':
        return (
          <FormikTextField
            required
            fullWidth
            fieldName={fullFieldName}
          />
        );
      case 'select':
        return (
          <FormikSelectField
            required
            fullWidth
            onChange={onChange}
            options={brandOptions}
            fieldName={fullFieldName}
          />
        );
      default:
        return null;
    }
  };

  const isVisibleNestedField = nested
  && currentNestedField.value
  && nested[currentNestedField.value];

  useEffect(() => () => {
    currentNestedHelpers.setTouched(false);
  }, []);

  return (
    <>
      <Grid item xs={4}>
        { getElement()}
      </Grid>
      {isVisibleNestedField && (
        <AddOrderRequestTypeField
          itemCount={itemCount}
          schema={nested[currentNestedField.value]}
        />
      )}
    </>
  );
};

export default AddOrderRequestTypeField;
