import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_WIRELINE_ENGAGEMENT_PATHS, ACTIONS_WIRELINE_ENGAGEMENT_ACTIVE_NUMBERS_PATHS } from 'core/models/actionsPermissions';

const { ORDER_CREATE } = ACTIONS_WIRELINE_ENGAGEMENT_PATHS;
const {
  ORDER_DATA_GET,
  ORDER_PARTIAL_EDIT,
  CANCEL_ORDER_CREATE,
  COMMENTS_GENERAL_GET,
  COMMENTS_BILLING_GET,
  ESCALATION_SUPPORT_CREATE,
  ENGAGEMENT_ACCEPT_PM_CREATE,
  EXPORT_ACTIVE_NUMBERS_TABLE_GET,
  ENGAGEMENT_ACCEPT_BILLING_CREATE,
} = ACTIONS_WIRELINE_ENGAGEMENT_ACTIVE_NUMBERS_PATHS;

const actionsPaths = {
  isAvailableCreateOrder: ORDER_CREATE,
  isAvailableGetOrderData: ORDER_DATA_GET,
  isAvailableEditOrder: ORDER_PARTIAL_EDIT,
  isAvailableCancelOrder: CANCEL_ORDER_CREATE,
  isAvailableGetGeneralComments: COMMENTS_GENERAL_GET,
  isAvailableGetBillingComments: COMMENTS_BILLING_GET,
  isAvailableEscalationSupport: ESCALATION_SUPPORT_CREATE,
  isAvailableEngagementAcceptPM: ENGAGEMENT_ACCEPT_PM_CREATE,
  isAvailableExportTableData: EXPORT_ACTIVE_NUMBERS_TABLE_GET,
  isAvailableEngagementAcceptBilling: ENGAGEMENT_ACCEPT_BILLING_CREATE,
};

export const useActiveNumbersPermissions = () => {
  const {
    isAvailableEditOrder,
    isAvailableCancelOrder,
    isAvailableCreateOrder,
    isAvailableGetOrderData,
    isAvailableExportTableData,
    isAvailableEscalationSupport,
    isAvailableGetGeneralComments,
    isAvailableGetBillingComments,
    isAvailableEngagementAcceptPM,
    isAvailableEngagementAcceptBilling,
  } = useActionsPermissions(actionsPaths);

  const isAvailableGetComments = isAvailableGetGeneralComments || isAvailableGetBillingComments;

  const isAvailableMoreActions = isAvailableCancelOrder
    || isAvailableGetOrderData
    || isAvailableGetComments
    || isAvailableEscalationSupport;

  const isAvailableSomeRowAction = isAvailableEditOrder || isAvailableMoreActions;
  const isAvailableTwoRowAction = isAvailableEditOrder && isAvailableMoreActions;

  const isAvailableAllRolesEngagementAccepted = isAvailableEngagementAcceptPM
    && isAvailableEngagementAcceptBilling;

  const isAvailableTableActions = isAvailableEngagementAcceptPM
    || isAvailableEngagementAcceptBilling;

  return {
    isAvailableEditOrder,
    isAvailableCreateOrder,
    isAvailableMoreActions,
    isAvailableGetComments,
    isAvailableCancelOrder,
    isAvailableTwoRowAction,
    isAvailableGetOrderData,
    isAvailableTableActions,
    isAvailableSomeRowAction,
    isAvailableExportTableData,
    isAvailableEscalationSupport,
    isAvailableEngagementAcceptPM,
    isAvailableEngagementAcceptBilling,
    isAvailableAllRolesEngagementAccepted,
  };
};
