import React from 'react';
import { useParams } from 'react-router-dom';
import { firstPage } from 'shared/Table/constants';
import { useTableContext } from 'shared/Table/TableContext';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { useUserPermissions } from '@EngagementsSowData/useSowDataPermissions';
import AddOrderModalForm from 'pages/WirelineEngagements/shared/AddOrderModalForm';
import DuplicateOrderModal from 'pages/WirelineEngagements/shared/DuplicateOrderModal';
import ActionsModal from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal';
import { isOrderAttTSD } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import DataRowSidebar from '@EngagementsSowData/WirelineEngagementsSowDataModals/DataRowSidebar';
import CommentsSidebar from '@EngagementsSowData/WirelineEngagementsSowDataModals/CommentsSidebar';
import CancelOrderModal from '@EngagementsSowData/WirelineEngagementsSowDataModals/CancelOrderModal';
import EditRowSidebarForm from '@EngagementsSowData/WirelineEngagementsSowDataModals/EditRowSidebarForm';
import EscalationSupportModal from '@EngagementsSowData/WirelineEngagementsSowDataModals/EscalationSupportModal';

const WirelineEngagementsSowDataModals = ({
  orderAction,
  selectedOrders,
  addOrderModalType,
  isActionsModalOpen,
  setAddOrderModalType,
  setIsActionsModalOpen,
}) => {
  const { customerId } = useParams();
  const { setPagination, getTableData } = useTableContext();
  const {
    activeRow,
    activeRowMode,
    cleanActiveRow,
  } = useTableActiveRowContext<ISowDataListItem>();

  const {
    isAvailableEditOrder,
    isAvailableCreateOrder,
    isAvailableCancelOrder,
    isAvailableGetComments,
    isAvailableGetOrderData,
    isAvailableQuoteRequest,
    isAvailableDuplicateOrder,
    isAvailableEscalationSupportOrder,
  } = useUserPermissions();

  const isTsdAtt = isOrderAttTSD(activeRow);
  const isVisibleOrderDataSidebar = isTsdAtt && isAvailableGetOrderData;

  const onAddProductSuccessCallback = () => {
    setPagination((paging) => ({
      ...paging,
      pageIndex: firstPage,
    }));

    getTableData();
  };

  return (
    <>
      { isAvailableEditOrder && <EditRowSidebarForm /> }
      { isAvailableEscalationSupportOrder && <EscalationSupportModal /> }
      { isAvailableCancelOrder && <CancelOrderModal /> }
      {
        isAvailableDuplicateOrder
          && (
            <DuplicateOrderModal
              activeRowMode={activeRowMode}
              activeRow={activeRow}
              cleanActiveRow={cleanActiveRow}
            />
          )
      }
      {
        isAvailableCreateOrder
          && (
            <AddOrderModalForm
              customerId={Number(customerId)}
              orderModalType={addOrderModalType}
              setAddOrderModalType={setAddOrderModalType}
              onSubmitSuccessCallback={onAddProductSuccessCallback}
            />
          )
      }
      {
        isAvailableQuoteRequest && (
          <ActionsModal
            orderAction={orderAction}
            selectedOrders={selectedOrders}
            isModalOpen={isActionsModalOpen}
            setIsActionsModalOpen={setIsActionsModalOpen}
          />
        )
      }
      { isAvailableGetComments && <CommentsSidebar /> }
      { isVisibleOrderDataSidebar && <DataRowSidebar /> }
    </>
  );
};

export default WirelineEngagementsSowDataModals;
