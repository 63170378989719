import { Grid, Typography } from '@mui/material';
import EngagementsFormGeneralEngagement from '@EngagementsForm/EngagementsFormGeneral/EngagementsFormGeneralEngagement';
import EngagementsFormGeneralCustomerInformation
  from '@EngagementsForm/EngagementsFormGeneral/EngagementsFormGeneralCustomerInformation';
import React from 'react';
import { useFormikContext } from 'formik';
import { useEngagementsFormPermission } from '@EngagementsForm/useEngagementsFormPermission';
import { useParams } from 'react-router-dom';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import EditSaveControls from 'shared/EditSaveControls';

const EngagementFormGeneralEditMode = () => {
  const { submitForm, resetForm } = useFormikContext();
  const { wirelineEngagementId } = useParams();
  const {
    isEditModeGeneral, setIsEditModeGeneral,
  } = useWirelineEngagementsCreateContext();

  const {
    isAvailableEditEngagement,
    isAvailableCreateEngagement,
  } = useEngagementsFormPermission();

  const isCreateMode = !wirelineEngagementId;

  const isVisibleActionButton = (isCreateMode && isAvailableCreateEngagement)
        || (!isCreateMode && isAvailableEditEngagement);

  return (
    <>
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Typography
          className="engagements-forms__section-title"
          variant="body1"
          component="p"
        >
          Customer Information
        </Typography>
        {isVisibleActionButton && (
        <EditSaveControls
          onSave={submitForm}
          onCancelEdit={resetForm}
          isCreateMode={isCreateMode}
          isEditMode={isEditModeGeneral}
          setIsEditMode={setIsEditModeGeneral}
          isSubmitButtonHidden
        />
        )}
      </Grid>
      <EngagementsFormGeneralEngagement onSave={submitForm} />
      <EngagementsFormGeneralCustomerInformation />
    </>
  );
};

export default EngagementFormGeneralEditMode;
