import React from 'react';
import { useFormikContext } from 'formik';
import { Grid, Typography } from '@mui/material';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikRadioGroup from 'shared/FormikField/FormikRadioGroup/FormikRadioGroup';
import FormikMobileMaskField from 'shared/FormikField/FormikMobileMaskField/FormikMobileMaskField';
import FormikSwitchField from 'shared/FormikField/FormikSwitchField';
import EditSaveControls from 'shared/EditSaveControls';

import { userStatusOptions, UserTypeOptions } from 'pages/UserManagement/constants';
import { useUserManagementCreateContext } from 'pages/UserManagement/UserManagementCreate/UserManagementCreateContext';
import { IUserWithTeamRoles } from 'pages/UserManagement/UserManagement.models';
import { BRAND_TYPE_OPTIONS } from 'pages/UserManagement/UserManagementCreate/constants';
import { useAppSelector } from 'core/store';

const UserManagementCreateFormGeneral = () => {
  const {
    isUserFilled, isUserHasEmailOnly, isEditModeGeneral, setIsEditModeGeneral, isEditModeTeamRole,
  } = useUserManagementCreateContext();
  const { resetForm, submitForm } = useFormikContext<IUserWithTeamRoles>();
  const brandName = useAppSelector((state) => state.appVariables.data.brand);

  const isCreateMode = !isUserFilled;
  const isFieldsDisabled = !isEditModeGeneral && !isCreateMode;
  const isEmailDisabled = isUserFilled || isUserHasEmailOnly || isFieldsDisabled;
  const isTypeDisabled = isUserFilled || isFieldsDisabled;
  const isAllowDirectLoginDisabled = isUserFilled || isFieldsDisabled;

  return (
    <Grid
      className="user-management-create-form__section"
      container
      direction="row"
      columnSpacing={5}
    >
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Typography
          className="user-management-create-form__section-title"
          variant="body1"
          component="p"
        >
          General
        </Typography>
        <EditSaveControls
          onCancelEdit={resetForm}
          onSave={submitForm}
          isEditMode={isEditModeGeneral}
          setIsEditMode={setIsEditModeGeneral}
          isCreateMode={isCreateMode}
          isDisabled={isEditModeTeamRole}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        rowSpacing={3}
        md={6}
        xl={4}
      >
        <FormikTextField
          label="First Name"
          fieldName="firstName"
          required
          fullWidth
          disabled={isFieldsDisabled}
        />
        <FormikTextField
          label="Last Name"
          fieldName="lastName"
          required
          fullWidth
          disabled={isFieldsDisabled}
        />
        <FormikTextField
          label="Email"
          fieldName="email"
          disabled={isEmailDisabled}
          required
          fullWidth
        />
        <FormikRadioGroup
          label="User Type"
          fieldName="type"
          options={BRAND_TYPE_OPTIONS[brandName] || UserTypeOptions}
          disabled={isTypeDisabled}
          required
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        rowSpacing={3}
        md={6}
        xl={4}
      >
        <FormikSwitchField
          label="Allow Direct Login"
          fieldName="allowDirectLogin"
          required
          disabled={isAllowDirectLoginDisabled}
        />
        <FormikMobileMaskField
          fieldName="phoneNumber"
          label="Phone Number"
          required
          fullWidth
          disabled={isFieldsDisabled}
        />
        <FormikRadioGroup
          label="Status"
          fieldName="isActive"
          options={userStatusOptions}
          required
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default UserManagementCreateFormGeneral;
