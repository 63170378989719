import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'core/store';

import ChatWidget from 'shared/ChatWidget';
import flareIcon from 'assets/images/flareLogo.svg';
import CommentIcon from '@mui/icons-material/ModeCommentOutlined';
import ToggleButton from 'layouts/Sidebar/ChatWidgetWrapper/ToggleButton';
import { postGuideChatMessageAction, clearGuideChatAction } from 'layouts/Sidebar/ChatWidgetWrapper/GuideChat';

import './ChatWidgetWrapper.scss';

const ChatWidgetWrapper = () => {
  const dispatch = useAppDispatch();

  const messages = useAppSelector((state) => state.guideChat.data);
  const isLoadingRespond = useAppSelector((state) => state.guideChat.isLoading);

  const menuItem = (
    isOpen, onToggleChat,
  ) => (<ToggleButton isOpen={isOpen} toggleHandler={onToggleChat} />);

  const brandIcon = <img src={flareIcon} alt="brand-icon" />;

  const onSendHandler = (text: string) => {
    dispatch(postGuideChatMessageAction({ message: text }));
  };

  useEffect(() => () => {
    dispatch(clearGuideChatAction());
  }, []);

  return (
    <ChatWidget
      title="FLAI"
      messages={messages}
      brandIcon={brandIcon}
      onSend={onSendHandler}
      subtitle="Support Agent"
      renderChatLauncher={menuItem}
      isLoadingResponce={isLoadingRespond}
      classNameMessagesCount="messages_count"
      botIcon={<CommentIcon className="icon-bot" />}
    />
  );
};

export default ChatWidgetWrapper;
