import React from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OrderDataIcon from '@mui/icons-material/DescriptionOutlined';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { useUserPermissions } from '@EngagementsSowData/useSowDataPermissions';
import { checkIfOrderNonAttTSD, isActiveNumbersItemCanceled } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';

interface IWirelineEngagementsSowDataTableRowActionsProps {
  row: ISowDataListItem
}

const WirelineEngagementsSowDataTableRowActions: React.FC<
  IWirelineEngagementsSowDataTableRowActionsProps
> = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,

    setActiveRowView,
    setActiveRowEdit,
    setActiveRowComment,
    setActiveRowOrderData,
    setActiveRowCancelOrder,
    setActiveRowDuplicateOrder,
    setActiveRowEscalationSupport,
  } = useTableActiveRowContext<ISowDataListItem>();

  const rowId = get(row, 'id');
  const isCanceled = isActiveNumbersItemCanceled(row);
  const isRowNonAttTSD = checkIfOrderNonAttTSD(row);

  const {
    isEscalationSupportMode, isCancelOrderMode, isCommentMode, isDataMode, isDuplicateMode,
  } = getTableRowModeBoolean(activeRowMode);

  const isEscalationSupportBtnSelected = isEscalationSupportMode && rowId === activeRowId;
  const isCancelButtonSelected = isCancelOrderMode && rowId === activeRowId;
  const isCommentButtonSelected = isCommentMode && rowId === activeRowId;
  const isOrderDataButtonSelected = isDataMode && rowId === activeRowId;
  const isDuplicateButtonSelected = isDuplicateMode && rowId === activeRowId;

  const {
    isAvailableEditOrder,
    isAvailableGetOrderData,
    isAvailableCancelOrder,
    isAvailableGetComments,
    isAvailableDuplicateOrder,
    isAvailableEscalationSupportOrder,
  } = useUserPermissions();

  const isVisibleOrderDataSidebar = !isRowNonAttTSD && isAvailableGetOrderData;

  const isEscalationSupportBtnDisabled = (
    (!!activeRowId && !isEscalationSupportBtnSelected) || isCanceled
  );

  const isCancelButtonDisabled = (
    (!!activeRowId && !isCancelButtonSelected)
    || isCanceled || isRowNonAttTSD
  );
  const isCommentButtonDisabled = !!activeRowId && !isCommentButtonSelected;

  const isOrderDataButtonDisabled = !!activeRowId && !isOrderDataButtonSelected;

  const isDuplicateButtonDisabled = !!activeRowId && isDuplicateButtonSelected;

  const isAllActionButtonDisabled = isEscalationSupportBtnDisabled
   && isCancelButtonDisabled
   && isCommentButtonDisabled
   && isOrderDataButtonDisabled;

  const moreButtonConfig = compact([
    (isAvailableEscalationSupportOrder && {
      onClick: () => setActiveRowEscalationSupport(row),
      isDisabled: isEscalationSupportBtnDisabled,
      icon: <NextWeekOutlinedIcon />,
      text: 'Escalation Support',
    }),
    (isAvailableCancelOrder && {
      onClick: () => setActiveRowCancelOrder(row),
      isDisabled: isCancelButtonDisabled,
      icon: <ClearIcon />,
      text: 'Cancel/Disco Request',
    }),
    (isAvailableGetComments && {
      onClick: () => setActiveRowComment(row),
      isDisabled: isCommentButtonDisabled,
      icon: <CommentIcon />,
      text: 'Order Comments',
    }),
    (isVisibleOrderDataSidebar && {
      onClick: () => setActiveRowOrderData(row),
      isDisabled: isOrderDataButtonDisabled,
      icon: <OrderDataIcon />,
      text: 'Order Data',
    }),
    (isAvailableDuplicateOrder && {
      onClick: () => setActiveRowDuplicateOrder(row),
      isDisabled: isDuplicateButtonDisabled,
      icon: <ContentCopyIcon />,
      text: 'Duplicate Order',
    }),
  ]);

  return (
    <Actions<ISowDataListItem>
      row={row}
      activeRowId={activeRowId}
      isViewButton={isCanceled}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      setActiveRowView={setActiveRowView}
      moreButtonConfig={moreButtonConfig}
      moreButtonTooltipTitle={orderActionTitle}
      isActionButtonVisible={isAvailableEditOrder}
      isMoreButtonDisabled={isAllActionButtonDisabled}
    />
  );
};

export default WirelineEngagementsSowDataTableRowActions;
