import uniqueId from 'lodash/uniqueId';
// eslint-disable-next-line no-unused-vars
import { IMessage } from 'shared/ChatWidget';
// eslint-disable-next-line no-unused-vars
import { IGuideChatCreateMessageParams, IGuideChatCreateMessage } from './GuideChat.models';

export const addUserMessage = (
  userMessageData: IGuideChatCreateMessageParams,
  messages: IMessage[],
): IMessage[] => {
  const newMessage = {
    id: uniqueId('user_message_'),
    text: userMessageData?.message || '',
    isBot: false,
  };

  return [...messages, newMessage];
};

export const addBotMessage = (
  botMessageData: IGuideChatCreateMessage,
  messages: IMessage[],
): IMessage[] => {
  const newMessage = {
    id: uniqueId('bot_message_'),
    text: botMessageData?.response || '',
    isBot: true,
  };

  return [...messages, newMessage];
};
