import React, { useState } from 'react';
import noop from 'lodash/noop';
import Table from 'shared/Table';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector } from 'core/store';
import CreateButton from 'shared/CreateButton';
import { TRow } from 'shared/Table/Table.models';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTableContext } from 'shared/Table/TableContext';
import ExportSowTable from '@EngagementsSowData/ExportSowTable';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import OrdersActionsMenu from '@EngagementsSowData/OrdersActionsMenu';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { useUserPermissions } from '@EngagementsSowData/useSowDataPermissions';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import WirelineEngagementsSowDataModals from '@EngagementsSowData/WirelineEngagementsSowDataModals';
import { TAddOrderType, ADD_ORDER_TYPE } from 'pages/WirelineEngagements/WirelineEngagements.models';
import WirelineEngagementsSowDataTableRowActions from '@EngagementsSowData/WirelineEngagementsSowDataTableRowActions';
import { defaultColumnPinning, muiTableContainerProps } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import {
  colDefOptionSelect,
  colDefOptionActionsTwoIcons,
  colDefOptionActionsSingleIcon,
} from 'shared/Table/constants';

const WirelineEngagementsSowDataTable = () => {
  const { rowSelection } = useTableContext();

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext<ISowDataListItem>();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const {
    isAvailableMoreAction,
    isAvailableCreateOrder,
    isAvailableTableActions,
    isAvailableTwoRowAction,
    isAvailableSomeRowAction,
    isAvailableExportSOWDataTable,
  } = useUserPermissions();

  const results = useAppSelector((state) => state.wirelineEngagements.salesSow.results);
  const count = useAppSelector((state) => state.wirelineEngagements.salesSow.count);
  const isLoading = useAppSelector((state) => state.wirelineEngagements.salesSow.isLoading);

  const isAddProductButtonDisabled = isEditMode
    || isViewMode
    || !isEmpty(rowSelection);

  const [addOrderModalType, setAddOrderModalType] = useState<TAddOrderType>(null);

  const selectedOrders = useTableSelectedRows<ISowDataListItem>({ rows: results });

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [orderAction, setOrderAction] = useState(OrderActions.REQUEST_QUOTES);

  const openAddProductModal = () => setAddOrderModalType(ADD_ORDER_TYPE.NEW_ORDER);

  const addProductActionComponent = secondarySidebarMode ? <MoreHorizIcon /> : 'add order';

  const addProductAction = isAvailableCreateOrder ? (
    <CreateButton
      className="sow-data-btn"
      onClick={openAddProductModal}
      text={addProductActionComponent}
      disabled={isAddProductButtonDisabled}
    />
  ) : undefined;

  const renderBottomToolbarCustomActions = isAvailableExportSOWDataTable ? () => (
    <ExportSowTable sowDataItem={results[0]} />
  ) : undefined;

  const actionButton = isAvailableTableActions
    ? (
      <OrdersActionsMenu
        selectedOrders={selectedOrders}
        setOrderAction={setOrderAction}
        setIsActionsModalOpen={setIsActionsModalOpen}
      />
    )
    : undefined;

  const renderRowActions = isAvailableMoreAction ? ({ row: { original } }) => (
    <WirelineEngagementsSowDataTableRowActions
      row={original as unknown as ISowDataListItem}
    />
  ) : undefined;

  return (
    <>
      <Table
        className="wireline-table"
        isLoading={isLoading}
        rowCount={count}
        toolBarAction={addProductAction}
        showColumnFilters
        showGlobalFilter
        handleRowClick={noop}
        enableSelectAll={false}
        rows={results as unknown as TRow[]}
        initialColumnPinning={defaultColumnPinning}
        muiTableContainerProps={muiTableContainerProps}
        enableStickyHeader
        actionsButton={actionButton}
        renderRowActions={renderRowActions}
        displayColumnDefOptions={{
          ...(isAvailableTableActions && colDefOptionSelect),
          ...(isAvailableSomeRowAction && colDefOptionActionsSingleIcon),
          ...(isAvailableTwoRowAction && colDefOptionActionsTwoIcons),
        }}
        renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
      />
      <WirelineEngagementsSowDataModals
        orderAction={orderAction}
        selectedOrders={selectedOrders}
        addOrderModalType={addOrderModalType}
        isActionsModalOpen={isActionsModalOpen}
        setAddOrderModalType={setAddOrderModalType}
        setIsActionsModalOpen={setIsActionsModalOpen}
      />
    </>
  );
};

export default WirelineEngagementsSowDataTable;
