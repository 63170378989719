import { useState, useRef, useEffect } from 'react';

import { CONTINUONS, INTERIM_RESULTS } from 'shared/ChatWidget/constants';

interface ISpeechRecognitionProps {
  onChangeTranscript: (transcript: string) => void
}

interface ISpeechRecognitionReturn {
  isSpeech: boolean
  transcript: string
  isListening: boolean
  error: string | null
  stopListening: () => void
  startListening: () => void
  isSpeechRecognitionSupported: boolean
}

export const useSpeechRecognition = (
  { onChangeTranscript }: ISpeechRecognitionProps,
): ISpeechRecognitionReturn => {
  const recognitionRef = useRef<SpeechRecognition | null>(null);

  const [isListening, setIsListening] = useState(false);
  const [isSpeech, setIsSpeech] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [error, setError] = useState<string | null>(null);

  const isSpeechRecognitionSupported = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;

  const onResult = (event: SpeechRecognitionEvent) => {
    const interimTranscript = Array.from(event.results)
      .map((result) => result[0].transcript)
      .join('');
    setTranscript(interimTranscript);
    onChangeTranscript?.(interimTranscript);
  };

  const onEnd = () => {
    setIsListening(false);
  };

  const onError = (event: SpeechRecognitionErrorEvent) => {
    setError(event.error);
    setIsListening(false);
  };

  const onSoundStart = () => {
    setIsSpeech(true);
  };

  const onSoundEnd = () => {
    setIsSpeech(false);
  };

  const startListening = () => {
    if (!recognitionRef.current) return;

    setIsListening(true);
    recognitionRef.current.start();
  };

  const stopListening = () => {
    if (!recognitionRef.current) return;

    setIsListening(false);
    recognitionRef.current.stop();
  };

  const setupParameters = () => {
    if (!recognitionRef.current) return;

    recognitionRef.current.continuous = CONTINUONS;
    recognitionRef.current.interimResults = INTERIM_RESULTS;
  };

  const setupEvents = () => {
    if (!recognitionRef.current) return;

    recognitionRef.current.onresult = onResult;
    recognitionRef.current.onerror = onError;
    recognitionRef.current.onend = onEnd;
    recognitionRef.current.onsoundstart = onSoundStart;
    recognitionRef.current.onsoundend = onSoundEnd;
  };

  const recognitionInitialization = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) return;

    recognitionRef.current = new SpeechRecognition();

    setupParameters();
    setupEvents();
  };

  const abortRecognition = () => {
    recognitionRef.current?.abort();
  };

  useEffect(() => {
    if (!isSpeechRecognitionSupported) return;

    recognitionInitialization();

    return () => {
      abortRecognition();
    };
  }, []);

  return {
    error,
    isSpeech,
    transcript,
    isListening,
    stopListening,
    startListening,
    isSpeechRecognitionSupported,
  };
};
