import { attLabel } from 'core/labels';
import { isNil, camelCase, isPlainObject } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { IOpenapiSchemasParsedItem } from 'core/models/openapi.models';
import { ORDER_TYPE, PmEngagementType } from '@EngagementsSowData/sowData.model';
import { sellerRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import {
// eslint-disable-next-line no-unused-vars
  IParsedSchemaDataItem, ICommonOrderData,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

import {
  // eslint-disable-next-line no-unused-vars
  IOrderData, IOrderDataSidebarItem, IParsedOrderData, IOrderDataItemValue,
} from 'core/models/order.models';

interface IRowData {
  tsd: string
  pmEngagementType: string | null
  contractOrderSupport: string | null
}

export const checkIfOrderNonAttTSD = (
  rowData: IRowData,
) => rowData.tsd !== attLabel;

export const isActiveNumbersItemCanceled = (
  row: IRowData,
) => row?.contractOrderSupport === ORDER_TYPE.CANCELED
|| row?.pmEngagementType === PmEngagementType.CANCELED;

export const getParsedRowData = (
  {
    isEditable,
    rowData = {},
    includedFields = [],
  }:
   {
    isEditable: boolean
    rowData: IOrderData,
    includedFields?: string[],
  }): IParsedOrderData => Object.entries(rowData)
  .reduce((value, [key, data]) => (
    (data.editable === isEditable) || includedFields.includes(key)
      ? { ...value, [key]: data.value }
      : value),
  {});

export const getOrderFormData = (rowData: IOrderData): IParsedOrderData => Object.entries(rowData)
  .reduce((value, [key, data]) => (
    { ...value, [key]: data.value }),
  {});

export const isOrderAttTSD = (
  rowData: IRowData | null,
) => rowData?.tsd === attLabel;

export const getLabelesFromShema = (
  shema: IOpenapiSchemasParsedItem[],
  startTitle?: string,
  startFieldName?: string,
) => shema
  .reduce((data, {
    title, properties, fieldName, description,
  }) => {
    const fullTitle = startTitle ? `${startTitle} ${title}` : title;
    const fullFieldName = startFieldName ? camelCase(`${startFieldName}_${fieldName}`) : fieldName;
    if (properties) {
      const nestedLabelesData = getLabelesFromShema(properties, fullTitle, fullFieldName);
      return { ...data, ...nestedLabelesData };
    }

    return { ...data, [fullFieldName]: { description, label: fullTitle } };
  }, {});

const getLabelFromDescription = (description: string, key: string | number | null) => {
  if (isNil(key) || !String(key)) return key;

  const regex = new RegExp(`\\*\\s\`?${key}\`?\\s-\\s(.*)`);
  const match = description.match(regex);

  return match ? match[1].trim() : key;
};

export const getOrderDataFromMeta = (
  meta: IParsedSchemaDataItem = {},
  data: IParsedOrderData,
  path?: string,
): IOrderDataSidebarItem[] => Object.entries(data)
  .map(([key, value]) => {
    const fullKey = path ? camelCase(`${path}_${key}`) : key;

    const isNestedObject = isPlainObject(value);
    if (isNestedObject) {
      return getOrderDataFromMeta(meta, value as IParsedOrderData, fullKey);
    }

    const metaItemData = meta[fullKey];
    const label = metaItemData?.label || fullKey;
    const parsedValue = metaItemData?.description
      ? getLabelFromDescription(metaItemData.description, value as string)
      : value as IOrderDataItemValue;

    return { label, value: parsedValue };
  })
  .flat();

export const checkIfOrdersRequestTypeIsNonCotractSupport = (
  sowDataRowsData: ICommonOrderData[],
) => sowDataRowsData.some((
  item,
) => item.sellerRequestType !== sellerRequestTypeValues.contractSupport);
