import React, { memo } from 'react';
import cx from 'classnames';

import { ReactComponent as SendIcon } from 'assets/widgetImages/send.svg';

interface ISendButtonProps {
  onSend: () => void
  isDisabled: boolean
  className?: string | undefined
}

const SendButton: React.FC<ISendButtonProps> = ({
  onSend,
  className,
  isDisabled,
}) => (
  <button
    type="button"
    onClick={onSend}
    disabled={isDisabled}
    data-testid="cw-send-button"
    className={cx('cw-chat-input__send-button', className)}
  >
    <SendIcon />
  </button>
);

SendButton.defaultProps = {
  className: '',
};

export default memo(SendButton);
